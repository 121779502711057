import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logolm from "../../images/large.png";
import logolm1 from "../../images/largewto.png";

const NavHeader = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="nav-header">
      <div className="brand-logo">
        <img className="logo-abbr" src={logolm} alt="" />
        
      </div>

      <img  className="brand-logo1" src={logolm1} alt=""/>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
    
  );
};

export default NavHeader;
