import React, { useState, useEffect } from "react";
import EIkconfig from "../Utils/EIKConfig_template.json";
import CreatableSelectScroll from "../Utils/CreatableSelectScroll";
import { Formik, Form, Field as FormikField, ErrorMessage } from "formik";
import { Card } from "react-bootstrap";
import TextField from "../Utils/TextField";
import EikConfigSchema from "../Validation/EikConfigSchema";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {encode as base64_encode} from 'base-64';
const authorizationUrlTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      OIDC Token Provider's Authorization Endpoint
  </Tooltip>
);

const tokenurlTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      OIDC Token Provider's Token endpoint
  </Tooltip>
);

const logouturlTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      OIDC Token Provider's Logout endpoint
  </Tooltip>
);

const introspectTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      OAuth Introspection endpoint
  </Tooltip>
);

const jwkurlTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      OIDC Token Provider's JWKS endpoint for token validation
  </Tooltip>
);

const issuerTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      OIDC Token Provider's Issuer included in the token 
  </Tooltip>
);

const userinfourlTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
     OIDC token providers UserInfo endpoint to retrieve consented claims 
  </Tooltip>
);
const trustStorePathTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      Location of the External Truststore file
  </Tooltip>
);
const clientidTooltip = (props) => (
  <Tooltip  id="button-tooltip"  {...props}  >
      Unique Identifier for EIK Client
  </Tooltip>
);
const clientsecretTooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Client Secret for the Client ID
  </Tooltip>
);
const scopeTooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Scopes requested to return user attribute from the OIDC token provider
  </Tooltip>
);
const claimsTooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Claim value returned from the OIDC provider
  </Tooltip>
);
const EBSLandingPageTooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Oracle EBS Homepage URL
  </Tooltip>
);
const contextPathTooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    EIK Context path (EIK deployment file name. For example, /EBSAuth)
  </Tooltip>
);
// const authenioninstanceTooltip = (props) => (
//   <Tooltip  id="button-tooltip"  {...props}  >
//       Authenion Instances to receive the Configuration
//   </Tooltip>
// );
const redirectURITooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    EIK's redirect or callback URL (For example, https://eik.com/EBSAuth/handler)
  </Tooltip>
);

const boldExampleStyle = {
  fontWeight: 'bold',
};

const cookiedomainTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Oracle EBS Domain value. For example:<span style={boldExampleStyle}> .myorg.com</span>
  </Tooltip>
);

const fndUsrCol = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Column Name in the FND_USER table for the User record validation
  </Tooltip>
);

const logoPath= (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    File Path to the Organization logo for branding
  </Tooltip>
);

const UsePrivateTrustStoreTooltip= (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
   Set External Truststore, if the default Java truststore is not being used
  </Tooltip>
);

const trustStorePasswordTooltip= (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Passphrase for the External Truststore
  </Tooltip>
);

const JWKSTooltip= (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Customized timout value for the JWKS validation
  </Tooltip>
);

const helpdeskTooltip= (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
    Customized message for the User-facing error pages
  </Tooltip>
);
const EikConfigurator = (props) => {
  const [, setData] = useState(props.data);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [scopes, setScopes] = useState([]);
  const jsonString = EIkconfig;
  let eikValues = jsonString;
  let [formvalues, setformvalues] = useState({});

   const handleInputChange = (event,inputIdentifier) => {
    setformvalues({
      ...formvalues,
      [inputIdentifier]: event.target.value,
      });
    };
 
  const [selectedOptionJIT, setSelectedOptionJIT] = useState(
    eikValues?.jitAccess || 'False'
  );
  
  const [selectedOption, setSelectedOption] = useState(
    eikValues?.authnType || 'OIDC'
  );

  const [selectedOptionUsePrivate, setSelectedOptionUsePrivate] = useState(
    eikValues?.usePrivateTrustStore || 'False'
  );
  
  const [isTrustStoreEnabled, setTrustStoreEnabled] = useState(selectedOptionUsePrivate === 'True');
 
  const data = JSON.parse(localStorage.getItem("data"));
  const cookieSettings=data?.cookieSettings;
  const tokenProvider = data?.tokenProvider;
  const { 
    authorizationUrl,
            tokenUrl,
           logoutUrl,
             jwksUrl,
              issuer,
         userInfoUrl,
             clientId,
          clientSecret,
                 scope}
                      = tokenProvider ?? {};
  const {cookieDomain} 
                   = cookieSettings ?? {};
const handleRadioChange = (event) => {
    const selectedRadioValue = event.target.value; 
    setSelectedOption(selectedRadioValue);
  };

  const handleRadioChangeforJIT = (event) => {
    setSelectedOptionJIT(event.target.value);
  };
  
  const handleRadioChangeforUsePrivate = (event) => {
    const selectedValue = event.target.value;
    setSelectedOptionUsePrivate(selectedValue);
    setTrustStoreEnabled(selectedValue === 'True');
  
   // Preserve other values and reset trustStore and trustStorePassword if usePrivateTrustStore is False
   if (selectedValue === 'False') {
    setformvalues((prevFormValues) => ({
      ...prevFormValues,
      trustStore: '',
      trustStorePassword: '',
    }));
  } else {
    const { helpdeskMessage, logoPath, fndUsrCol, jwkstimeout, authnAttribute, contextPath, ebsLandingPage, eikRedirectUri,cookieDomain } = formvalues;
    setformvalues((prevFormValues) => ({
      ...prevFormValues,
      helpdeskMessage,
      logoPath,
      fndUsrCol,
      jwkstimeout,
      authnAttribute,
      contextPath,
      ebsLandingPage,
      eikRedirectUri,
      cookieDomain,
    }));
  }
};
  
const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };

  const saveSuccess = () => {
    toast.success("Settings have been saved successfully!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };
  const loadUrlSuccess = () => {
    toast.success("Loading from Well Known configuration successfully!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };

  const loadUrlFailure = () => {
    toast.warning("Load Failed, Please check the Issuer Url!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };
  
  useEffect(() => {
    setData(props.data);
    const savedFormValues = localStorage.getItem('formvalues');
    if (savedFormValues) {
      setformvalues(JSON.parse(savedFormValues));
    }
  }, [props.data]);

  const downloadTxtFile = () => 
  {

    // Get current date and time
   const currentDate = new Date();
   const formattedDateTime = new Intl.DateTimeFormat('en-US', {
     weekday: 'short',
     month: 'short',
     day: '2-digit',
     hour: '2-digit',
     minute: '2-digit',
     second: '2-digit',
     timeZoneName: 'short',
     year: 'numeric',
   }).format(currentDate);

   if(tokenProvider && cookieSettings ) { 

     // Encode for clientId and clientSecret
        let encodedclientid = base64_encode(clientId);
        let encodedclientsecret = base64_encode(clientSecret);

     // EIKAuth.config text content
     const texts = [
       `#`,
       `# ${formattedDateTime}`,
      //`helpdeskMessage=<p>If issue persists, please contact technical <a href="https://support.likemindsconsulting.com">support</a></p>`,
       `#helpdeskMessage=${formvalues.helpdeskMessage ? formvalues.helpdeskMessage : ''}`,
       `ebs_logout_page=${logoutUrl ? logoutUrl : ''}`,
       `scope=${ scope ? scope : ''}`,
       `jwks_validation_url=${jwksUrl ? jwksUrl : ''}`,
       `ContextPath=${formvalues.contextPath ? formvalues.contextPath : []}`,
      //`logoPath=https://likemindsconsulting.com/wp-content/uploads/2020/07/logo-1.png`,
       `#logoPath=${formvalues.logoPath ? formvalues.logoPath : ''}`,
       `access_type_value=offline`,
       `token_endpoint_url=${tokenUrl ? tokenUrl : ''}`,
       `authn_attribute=${formvalues.authnAttribute ? formvalues.authnAttribute : ''}`,
       `grant_type=authorization_code`,
       `ebs_landing_page=${formvalues.ebsLandingPage ? formvalues.ebsLandingPage : ''}`,
       `approval_prompt_key=prompt`,
       `issuer=${issuer ? issuer : ''}`,
       `introspect_url=${formvalues.introspection_endpoint ? formvalues.introspection_endpoint : ''}`,
       `icx_cookie_path=/`,
       `auth_type=${formvalues.authnType ? formvalues.authnType : ''}`,
       `icx_cookie_domain=${formvalues.cookieDomain ? formvalues.cookieDomain : ''}`,
       `authentication_server_url=${authorizationUrl ? authorizationUrl : ''}`,
       `access_type_key=access_type`,
       `jit=${formvalues.jitAccess ? formvalues.jitAccess : ''}`,
       `client_id=${encodedclientid ? encodedclientid : ''}`,
       `redirect_uri=${formvalues.eikRedirectUri ? formvalues.eikRedirectUri : ''}`,
       `approval_prompt_value=login consent`,
       `client_secret=${encodedclientsecret ? encodedclientsecret : ''}`,
       `userinfo_url=${userInfoUrl ? userInfoUrl : ''}`,
       `#trustStorePath=${formvalues.trustStore ? formvalues.trustStore : ''}`,
      //`UsePrivateTrustStore=false`,
      //`trustStorePassword=changeit`,
      `#UsePrivateTrustStore=${formvalues.usePrivateTrustStore ? formvalues.usePrivateTrustStore : ''}`,
      `#trustStorePassword=${formvalues.trustStorePassword ? formvalues.trustStorePassword : ''}`,
      `#fndusr_column_name=${formvalues.fndUsrCol ? formvalues.fndUsrCol : ''}`,
      `#jwks_connect_timeout=${formvalues.jwkstimeout ? formvalues.jwkstimeout : ''}`
    
     ];
   
      // file object
      const file = new Blob([texts.join('\n')], { type: 'text/plain' });
      // anchor link
       const element = document.createElement("a");
       element.href = URL.createObjectURL(file);
       element.download = "EIKAuth.config";
     // simulate link click
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
   }

     else {

    // Encode for clientId and clientSecret
     let encodedclientid = base64_encode(formvalues.idpClientId);
     let encodedclientsecret = base64_encode(formvalues.idpClientSecret);
     const cookieDomain = formvalues.cookieDomain ? `.${formvalues.cookieDomain}` : '';

    // EIKAuth.config text content
    const texts = [
      `#`,
      `# ${formattedDateTime}`,
      //`helpdeskMessage=<p>If issue persists, please contact technical <a href="https://support.likemindsconsulting.com">support</a></p>`,
      `#helpdeskMessage=${formvalues.helpdeskMessage ? formvalues.helpdeskMessage : ''}`,
      `ebs_logout_page=${formvalues.end_session_endpoint ? formvalues.end_session_endpoint : ''}`,
      `scope=${ formvalues.scopes ? formvalues.scopes: ''}`,
      `jwks_validation_url=${formvalues.jwks_uri ? formvalues.jwks_uri : ''}`,
      `ContextPath=${formvalues.contextPath ? formvalues.contextPath : ''}`,
       //`logoPath=https://likemindsconsulting.com/wp-content/uploads/2020/07/logo-1.png`,
      `#logoPath=${formvalues.logoPath ? formvalues.logoPath : ''}`,
      `access_type_value=offline`,
      `token_endpoint_url=${formvalues.token_endpoint ? formvalues.token_endpoint : ''}`,
      `authn_attribute=${formvalues.authnAttribute ? formvalues.authnAttribute : ''}`,
      `grant_type=authorization_code`,
      `ebs_landing_page=${formvalues.ebsLandingPage ? formvalues.ebsLandingPage : ''}`,
      `approval_prompt_key=prompt`,
      `issuer=${formvalues.issuer ? formvalues.issuer : ''}`,
      `introspect_url=${formvalues.introspection_endpoint ? formvalues.introspection_endpoint : ''}`,
      `icx_cookie_path=/`,
      `auth_type=${formvalues.authnType ? formvalues.authnType : ''}`,
      `icx_cookie_domain=${formvalues.cookieDomain ? formvalues.cookieDomain : ''}`,
      `authentication_server_url=${formvalues.authorization_endpoint ? formvalues.authorization_endpoint : ''}`,
      `access_type_key=access_type`,
      `jit=${formvalues.jitAccess ? formvalues.jitAccess : ''}`,
      `client_id=${encodedclientid ? encodedclientid : ''}`,
      `redirect_uri=${formvalues.eikRedirectUri ? formvalues.eikRedirectUri : ''}`,
      `approval_prompt_value=login consent`,
      `client_secret=${encodedclientsecret ? encodedclientsecret : ''}`,
      `userinfo_url=${formvalues.userinfo_endpoint ? formvalues.userinfo_endpoint : ''}`,
      `#trustStorePath=${formvalues.trustStore ? formvalues.trustStore : ''}`,
      //`UsePrivateTrustStore=false`,
      //`trustStorePassword=changeit`,
      `#UsePrivateTrustStore=${formvalues.usePrivateTrustStore ? formvalues.usePrivateTrustStore : ''}`,
      `#trustStorePassword=${formvalues.trustStorePassword ? formvalues.trustStorePassword : ''}`,
      `#fndusr_column_name=${formvalues.fndUsrCol ? formvalues.fndUsrCol : ''}`,
      `#jwks_connect_timeout=${formvalues.jwkstimeout ? formvalues.jwkstimeout : ''}`
      ];
  
      // file object
       const file = new Blob([texts.join('\n')], { type: 'text/plain' });
       // anchor link
       const element = document.createElement("a");
       element.href = URL.createObjectURL(file);
       element.download = "EIKAuth.config";
      // simulate link click
       document.body.appendChild(element); // Required for this to work in FireFox
       element.click();
     }
   };

  const handleFormSubmit = async (values, setSubmitting) => {

    try {
      setSubmitting(true);
      const response = await fetch(values.issuer + "/.well-known/openid-configuration");

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      Object.entries(data).forEach(([key, value]) => {
        values[key] = value;
      });
     values.scopes_supported = data.scopes_supported;
      localStorage.setItem("formvalues", JSON.stringify(values));
      loadUrlSuccess();
    } catch (error) {
      loadUrlFailure();
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (

    <Formik
      initialValues={{
        ...eikValues,...formvalues,authorizationUrl,tokenUrl,logoutUrl,jwksUrl,
        issuer,userInfoUrl,clientId,clientSecret,scope,cookieDomain
       }}

      enableReinitialize={true}
      // validationSchema={EikConfigSchema}
      onSubmit={async (values, { setSubmitting }) => {
        let data = props.data;
        let newEikValues = {
          ...values,
          // contextPath: values.contextPath.map((obj) => obj.value),
          // eikAgentHostNames: values.eikAgentHostNames.map((obj) => obj.value),
          authnType: selectedOption,
          jitAccess: selectedOptionJIT,
          usePrivateTrustStore:selectedOptionUsePrivate
        };
        setSubmitting(true);
        setScopes(scopes);
        setformvalues(values);
        setData(data);
        formvalues = newEikValues;
        localStorage.setItem("formvalues", JSON.stringify(formvalues));
        saveSuccess();
        downloadTxtFile();
      }}
    >
      {({ values, setSubmitting }) => (
        <Form>
          <Card style={{ marginBottom: 0 }}>
            <Card.Body style={{ paddingBottom: 0 }}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between">
                <div className="mb-3 mr-3">
                  <h6 className="fs-18 text-black font-w600 mb-60">EIK Configurator - Choose your Authentication Type</h6>
                </div>
                {/* <div className="switch">
                  <input
                    style={{ backgroundColor: "green" }}
                    type="checkbox"
                    className="custombutton"
                    id="customSwitches"
                    checked={isEnabled}
                    onChange={handleToggle}
                  />
                  <label className="customlabel" htmlFor="customSwitches"></label>
                </div> */}
              </div>
              
            <div class="wrapper" >
              <label className={`option option-1 ${selectedOption === 'OIDC' ? 'clicked' : ''}`}>
                  <input type="radio" name="authnType"  value="OIDC" checked={selectedOption === 'OIDC'} onChange={handleRadioChange} />
                  <div class="dot"></div>
                  <span>OpenID Connect Login</span>
                </label>
                <label className={`option option-2 ${selectedOption === 'Header' ? 'clicked' : ''}`}>
                  <input type="radio" name="authnType"  value="Header" checked={selectedOption === 'Header'} onChange={handleRadioChange} />
                  <div class="dot"></div>
                  <span>HTTP-Header Login</span>
                </label>
              </div>

         {selectedOption === 'OIDC' && (
                <>
                  <div className="row" style={{ marginTop: "70px" }}>
                    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={issuerTooltip}>
                      <div className="col-sm-6">
                        <TextField name="issuer" type="text" label="Issuer Url" value={formvalues.issuer} />
                      </div>
                    </OverlayTrigger>

                    {!(showAdvanced) && (
                      <div className="col-sm-6">
                        <button type="button" className="btn btn-success text-nowrap" onClick={() => handleFormSubmit(values, setSubmitting)}>
                          Load metadata
                        </button>
                        <ToastContainer autoClose={2000} transition={Flip} />
                        <div
                          style={{ marginLeft: "195px", marginTop: "-40px", cursor: "pointer" }}
                          onClick={() => setShowAdvanced(!showAdvanced)}
                        >
                          {showAdvanced ? <u>Hide Advanced Configuration</u> : <u>Show Advanced Configuration</u>}
                        </div>
                      </div>
                    )}

                  </div>


                  {(showAdvanced) && (
                    <div className="row">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={authorizationUrlTooltip}>
                        <div className="col-sm-6">
                          {data !== null ? (
                            <FormikField name="authorizationUrl">
                              {({ field }) => (
                                <TextField label="Authorization Url" id="authorization_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          ) : (
                            <FormikField name="authorization_endpoint">
                              {({ field }) => (
                                <TextField label="Authorization Url" id="authorization_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          )}
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={tokenurlTooltip}>
                        <div className="col-sm-6">
                          {data !== null ? (
                            <FormikField name="tokenUrl">
                              {({ field }) => (
                                <TextField label="Token Endpoint" id="token_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          ) : (
                            <FormikField name="token_endpoint">
                              {({ field }) => (
                                <TextField label="Token Endpoint" id="token_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          )}
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={introspectTooltip}>
                        <div className="col-sm-6">
                          <FormikField name="introspection_endpoint">
                            {({ field }) => (
                              <TextField label="Introspection Url" id="introspection_endpoint" type="text" {...field} />
                            )}
                          </FormikField>
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={jwkurlTooltip}>
                        <div className="col-sm-6">
                          {data !== null ? (
                            <FormikField name="jwksUrl">
                              {({ field }) => (
                                <TextField label="JWKS Url" id="jwks_uri" type="text" {...field} />
                              )}
                            </FormikField>
                          ) : (
                            <FormikField name="jwks_uri">
                              {({ field }) => (
                                <TextField label="JWKS Url" id="jwks_uri" type="text" {...field} />
                              )}
                            </FormikField>
                          )}
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={userinfourlTooltip}>
                        <div className="col-sm-6">
                          {data !== null ? (
                            <FormikField name="userInfoUrl">
                              {({ field }) => (
                                <TextField label="UserInfo Url" id="userinfo_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          ) : (
                            <FormikField name="userinfo_endpoint">
                              {({ field }) => (
                                <TextField label="UserInfo Url" id="userinfo_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          )}
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={logouturlTooltip}>
                        <div className="col-sm-6">
                          {data !== null ? (
                            <FormikField name="logoutUrl">
                              {({ field }) => (
                                <TextField label="Logout Url" id="end_session_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          ) : (
                            <FormikField name="end_session_endpoint">
                              {({ field }) => (
                                <TextField label="Logout Url" id="end_session_endpoint" type="text" {...field} />
                              )}
                            </FormikField>
                          )}
                        </div>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={scopeTooltip}>

                        <div className="col-sm-6">
                        {data !== null ? (
                          <TextField name="scope" type="text" label="Scopes" />
                          ) : (
                            <TextField name="scopes" type="text" label="Scopes" />
                            )}
                        </div>

                      </OverlayTrigger>

                      <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={logoPath}
                    >
                      <div className="col-sm-6" >
                        <TextField name="logoPath" type="text" label="Logo Path" value={values.logoPath} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={helpdeskTooltip}
                    >
                      <div className="col-sm-6" >
                        <TextField name="helpdeskMessage" type="text" label="Helpdesk Message" value={values.helpdeskMessage} />
                      </div>
                    </OverlayTrigger>


                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={fndUsrCol}
                    >
                      <div className="col-sm-6" >
                        <TextField name="fndUsrCol" type="text" label="User Column in FND_USER table " value={values.fndUsrCol} />
                      </div>
                    </OverlayTrigger>

                    
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={UsePrivateTrustStoreTooltip}
                    >
                      {/* <div className="col-sm-6" >
                        <TextField name="usePrivateTrustStore" type="text" label="UsePrivate TrustStore" value={values.usePrivateTrustStore} />
                      </div> */}
                      <div className="col-sm-6">
                        <label className="font-w600">Use Private Truststore</label>
                         <tbody>
                            <tr>
                               <td style={{ width: "180px" }}>
                                 <label style={{ fontFamily: "500" }}>
                                   <span style={{ marginLeft: "20px" }}> True </span>
                                      <input
                                         type="radio"
                                         name="usePrivateTrustStore"
                                         value="True"
                                         checked={selectedOptionUsePrivate === 'True'}
                                         onChange={handleRadioChangeforUsePrivate}
                                         style={{ display: "block", marginLeft: "66px", marginTop: "-17px" }}
                                       />
                                  </label>
                              </td>
                               <td>
                                   <label style={{ fontFamily: "500" }}>
                                       <span style={{ marginLeft: "20px" }}> False</span>
                                       <input
                                            type="radio"
                                            name="usePrivateTrustStore"
                                            value="False"
                                            checked={selectedOptionUsePrivate === 'False'}
                                            onChange={handleRadioChangeforUsePrivate}
                                            style={{ display: "block", marginLeft: "70px", marginTop: "-17px" }}
                                        />
                                    </label>
                                 </td>
                             </tr>
                          </tbody>
                     </div>
                    </OverlayTrigger>
             
                   <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={trustStorePathTooltip}
                    >
                      <div className="col-sm-6" >
                      <label className={`font-w600 ${isTrustStoreEnabled ? '' : 'labelcolor'}`}>Truststore Path</label>
      
                                   <input
                                        className={`truststore-textfield ${isTrustStoreEnabled ? '' : 'boxcolor'}`}
                                         name="trustStore"
                                         type="text"
                                         value={values.trustStore}
                                         onChange={(event) => handleInputChange(event, 'trustStore')}
                                         readOnly={!isTrustStoreEnabled}
                    
                                    />
                         </div>
                    </OverlayTrigger>
                   
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={trustStorePasswordTooltip}
                    >
                  
                           <div className="col-sm-6" style={{marginTop:"10px" }}>
                           <label className={`font-w600 ${isTrustStoreEnabled ? '' : 'labelcolor'}`}>Truststore Password</label>
                                      <input
                                         className={`truststore-textfield ${isTrustStoreEnabled ? '' : 'boxcolor'}`}
                                          name="trustStorePassword"
                                          type="password"
                                          value={values.trustStorePassword}
                                          onChange={(event) => handleInputChange(event, 'trustStorePassword')}
                                          readOnly={!isTrustStoreEnabled}
                                       />
                             </div>
                    </OverlayTrigger>
                  
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={JWKSTooltip}
                    >
                        <div className="col-sm-6"  style={{marginTop:"10px" }}>
                               <TextField name="jwkstimeout" type="number" label="JWKS Timeout" value={values.jwkstimeout} />
                         </div>
                    </OverlayTrigger>

                        <div
                        style={{ marginLeft: "17px",color:"red", marginBottom: "20px",marginTop:"10px",cursor: "pointer"}}
                        onClick={() => setShowAdvanced(!showAdvanced)}
                      >
                        {showAdvanced ? <u>Hide Advanced Configuration</u> : <u>Show Advanced Configuration</u>}
                      </div>

                    </div>

                  )}

                  <div className="row">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={clientidTooltip}
                    >
                      <div className="col-sm-6">
                      {data !== null ? (
                       <TextField name="clientId" type="text" label="Client Id" value={values.clientId} />
                       ) : (
                        
                        <TextField name="idpClientId" type="text" label="Client Id" value={values.idpClientId} />
                      )}
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={clientsecretTooltip}
                    >
                      <div className="col-sm-6">
                      {data !== null ? (
                         <TextField name="clientSecret" type="password" label="Client Secret" value={values.clientSecret} />
                        ) : (
                          <TextField name="idpClientSecret" type="password" label="Client Secret" value={values.idpClientSecret} />
                        )}
                        </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={claimsTooltip}
                    >
                      <div className="col-sm-6">
                        <TextField name="authnAttribute" type="text" label="Authentication Attribute" value={values.authnAttribute} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={redirectURITooltip}
                    >
                      <div className="col-sm-6" >
                        <TextField name="eikRedirectUri" type="text" label="Redirect URI" value={values.eikRedirectUri} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={contextPathTooltip}
                    >

                      {/* <div className="col-sm-6">
                        <div className="input-field-redux form-group">
                          <label className="font-w600">Context Path</label>
                          <div>
                            <FormikField
                              className="form-control dropdown-form-control"
                              component={CreatableSelectScroll}
                              name="contextPath"
                              value={
                                formvalues.contextPath
                                  ? formvalues.contextPath.map((res) => ({
                                    value: res,
                                    label: res
                                  }))
                                  : []
                              }
                            />
                            <ErrorMessage
                              name={"contextPath"}
                              component="div"
                              className="required"
                            />
                          </div>
                        </div>
                      </div> */}

                    <div className="col-sm-6" >
                        <TextField name="contextPath" type="text" label="Context Path" value={values.contextPath} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={EBSLandingPageTooltip}
                    >
                      <div className="col-sm-6">
                        <TextField name="ebsLandingPage" type="text" label="EBS Default Homepage" value={values.ebsLandingPage} />
                      </div>
                    </OverlayTrigger>

                    
                     {/* <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={authenioninstanceTooltip}
                               >
                  <div className="col-sm-6">
                  <div className="input-field-redux form-group">
                    <label className="font-w600">Authenion Instances</label>
                    <div>
                    <FormikField
                              className="form-control dropdown-form-control"
                              component={CreatableSelectScroll}
                              name="eikAgentHostNames"
                              value={ data !==null ? data.tokenProvider.syncAgentHostNames.map((res) => ({
                                value: res,
                                label: res,
                              }))
                              : []
                              }>

                              </FormikField>
                      <ErrorMessage
                        name={"eikAgentHostNames"}
                        component="div"
                        className="required"
                      />
                    </div>
                  </div>
                </div>
              </OverlayTrigger> */}

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={cookiedomainTooltip}
                    >
                      <div className="col-sm-6">
                      {data !== null ? (
                         <TextField name="cookieDomain" type="text" label="Cookie Domain" value={values.cookieDomain}/>
                        ) : (
                          <TextField name="cookieDomain" type="text" label="Cookie Domain" value={values.cookieDomain} />
                        )}
                        
                      </div>
                    </OverlayTrigger>

                    <div className="col-sm-6">
                      <label className="font-w600">Just-In-Time Access</label>
                      <tbody>
                        <tr>
                          <td style={{ width: "180px" }}>
                            <label style={{ fontFamily: "500" }}>
                              {/* <input type="radio" name="jitAccess" value="True" checked={selectedOptionJIT === 'True'} onChange={handleRadioChangeforJIT} style={{display:"block"}}/> */}
                               {/* <span style={{ marginLeft: "5px" }}> True </span> */}
                              <span style={{ marginLeft: "20px" }}> True </span>
                                 <input type="radio" name="jitAccess" value="True" checked={selectedOptionJIT === 'True'} onChange={handleRadioChangeforJIT} style={{ display: "block", marginLeft: "66px", marginTop: "-17px" }} />
                            </label>
                          </td>
                          <td>
                            <label style={{ fontFamily: "500" }}>
                              <span style={{ marginLeft: "20px" }}> False</span>
                              <input type="radio" name="jitAccess" value="False" checked={selectedOptionJIT !== 'True'} onChange={handleRadioChangeforJIT} style={{ display: "block", marginLeft: "70px", marginTop: "-17px" }} />
                               {/* <input  type="radio" name="jitAccess" value="False" checked={selectedOptionJIT !== 'True'}  onChange={handleRadioChangeforJIT} style={{display:"block"}}/>
                                          <span style={{ marginLeft: "20px" }}> False</span> */}
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </div>
                </div>
                </>
              )}

              {/* </div>  */}
              {selectedOption === 'Header' && (
                <>
                  <div className="row" style={{ marginTop: "70px" }}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={claimsTooltip}
                    >
                      <div className="col-sm-6">
                        <TextField name="authnAttribute" type="text" label="Authentication Attribute" value={values.authnAttribute} />
                      </div>
                    </OverlayTrigger>

                     {/* <div className="col-sm-6">
                        <div className="input-field-redux form-group">
                          <label className="font-w600">Context Path</label>
                          <div>
                            <FormikField
                              className="form-control dropdown-form-control"
                              component={CreatableSelectScroll}
                              name="contextPath"
                              value={
                                formvalues.contextPath
                                  ? formvalues.contextPath.map((res) => ({
                                    value: res,
                                    label: res
                                  }))
                                  : []
                              }
                            />
                            <ErrorMessage
                              name={"contextPath"}
                              component="div"
                              className="required"
                            />
                          </div>
                        </div>
                      </div> */}

                  <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={contextPathTooltip}
                    >
                      <div className="col-sm-6" >
                        <TextField name="contextPath" type="text" label="Context Path" value={values.contextPath} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={EBSLandingPageTooltip}
                    >
                      <div className="col-sm-6">
                        <TextField name="ebsLandingPage" type="text" label="EBS Default Homepage" value={values.ebsLandingPage} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={redirectURITooltip}
                    >
                      <div className="col-sm-6">
                        <TextField name="eikRedirectUri" type="text" label="Redirect URI" value={values.eikRedirectUri} />
                      </div>
                    </OverlayTrigger>

                    {/* <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={authenioninstanceTooltip}
                               >
                            <div className="col-sm-6">
                  <div className="input-field-redux form-group">
                    <label className="font-w600">Authenion Instances</label>
                    <div>
                    <FormikField
                              className="form-control dropdown-form-control"
                              component={CreatableSelectScroll}
                              name="eikAgentHostNames"
                              value={data !==null ? data.tokenProvider.syncAgentHostNames.map((res) => ({
                                value: res,
                                label: res,
                              }))
                              : []
                              }>

                              </FormikField>
                      <ErrorMessage
                        name={"eikAgentHostNames"}
                        component="div"
                        className="required"
                      />
                    </div>
                  </div>
                </div>
                   </OverlayTrigger> */}

              <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={cookiedomainTooltip}
                    >
                      <div className="col-sm-6">
                      {data !== null ? (
                         <TextField name="cookieDomain" type="text" label="Cookie Domain" value={values.cookieDomain}/>
                        ) : (
                          <TextField name="cookieDomain" type="text" label="Cookie Domain" value={values.cookieDomain} />
                        )}
                        
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={logoPath}
                    >
                      <div className="col-sm-6" >
                        <TextField name="logoPath" type="text" label="Logo Path" value={values.logoPath} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={helpdeskTooltip}
                    >
                      <div className="col-sm-6" >
                        <TextField name="helpdeskMessage" type="text" label="Helpdesk Message" value={values.helpdeskMessage} />
                      </div>
                    </OverlayTrigger>


                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={fndUsrCol}
                    >
                      <div className="col-sm-6" >
                        <TextField name="fndUsrCol" type="text" label="User Column in FND_USER table " value={values.fndUsrCol} />
                      </div>
                    </OverlayTrigger>

                    
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={UsePrivateTrustStoreTooltip}
                    >
                      {/* <div className="col-sm-6" >
                        <TextField name="usePrivateTrustStore" type="text" label="UsePrivate TrustStore" value={values.usePrivateTrustStore} />
                      </div> */}
                      <div className="col-sm-6">
                        <label className="font-w600">Use Private Truststore</label>
                         <tbody>
                            <tr>
                               <td style={{ width: "180px" }}>
                                 <label style={{ fontFamily: "500" }}>
                                   <span style={{ marginLeft: "20px" }}> True </span>
                                      <input
                                         type="radio"
                                         name="usePrivateTrustStore"
                                         value="True"
                                         checked={selectedOptionUsePrivate === 'True'}
                                         onChange={handleRadioChangeforUsePrivate}
                                         style={{ display: "block", marginLeft: "66px", marginTop: "-17px" }}
                                       />
                                  </label>
                              </td>
                               <td>
                                   <label style={{ fontFamily: "500" }}>
                                       <span style={{ marginLeft: "20px" }}> False</span>
                                       <input
                                            type="radio"
                                            name="usePrivateTrustStore"
                                            value="False"
                                            checked={selectedOptionUsePrivate === 'False'}
                                            onChange={handleRadioChangeforUsePrivate}
                                            style={{ display: "block", marginLeft: "70px", marginTop: "-17px" }}
                                        />
                                    </label>
                                 </td>
                             </tr>
                          </tbody>
                     </div>
                    </OverlayTrigger>
             
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={trustStorePathTooltip}
                    >
                      <div className="col-sm-6" >
                      <label className={`font-w600 ${isTrustStoreEnabled ? '' : 'labelcolor'}`}>Truststore Path</label>
      
                                   <input
                                        className={`truststore-textfield ${isTrustStoreEnabled ? '' : 'boxcolor'}`}
                                         name="trustStore"
                                         type="text"
                                         value={values.trustStore}
                                         onChange={(event) => handleInputChange(event, 'trustStore')}
                                         readOnly={!isTrustStoreEnabled}
                    
                                    />
                         </div>
                    </OverlayTrigger>
                   
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={trustStorePasswordTooltip}
                    >
                  
                           <div className="col-sm-6" style={{marginTop:"10px" }}>
                           <label className={`font-w600 ${isTrustStoreEnabled ? '' : 'labelcolor'}`}>Truststore Password</label>
                                      <input
                                         className={`truststore-textfield ${isTrustStoreEnabled ? '' : 'boxcolor'}`}
                                          name="trustStorePassword"
                                          type="password"
                                          value={values.trustStorePassword}
                                          onChange={(event) => handleInputChange(event, 'trustStorePassword')}
                                          readOnly={!isTrustStoreEnabled}
                                       />
                             </div>
                    </OverlayTrigger>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              { }

              <div className="col-sm-6">
                <button type="submit" className="next btn btn-edit">
                  Save
                </button>
                <ToastContainer autoClose={2000} transition={Flip} />
              </div>

            </Card.Body>
          </Card>
       </Form>
      )}
    </Formik>
  );
};

export default EikConfigurator;