import React from "react";
import Application from './Application';

function Index(props){
	return(	
		<>
			<div className="row">
			    <div className="col-md-9">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Application</h4>
						</div>
						<div className="card-body">
							<Application/>
						</div>
					</div>	
				</div>
			</div>
		</>
	)
}
export default Index;