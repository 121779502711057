import React from "react";
import { Link } from "react-router-dom";

let findName = (list, id) => {
  let obj = list.find((obj) => obj.id === id);
  return obj ? obj.name : "";
};

var Resource = (props) => {
  return (
    <ul>
      {props.resources.map((resource) => {
        return (
          <li key={resource}>{findName(props.data.resources, resource)}</li>
        );
      })}
    </ul>
  );
};

const ApplicationList = (props) => {
  
  let deleteApplication = (id) => {
    let applications = props.data.applications.filter(function (obj) {
      return obj.id !== id;
    });
    props.data.applications = applications;
    localStorage.setItem("data", JSON.stringify(props.data));
  };

  return (
    <div id="All" className="tab-pane">
      <div className="table-responsive">
        <div id="example2_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="example2"
            className="table card-table display dataTablesCard dataTable no-footer"
            role="grid"
            aria-describedby="example2_info"
          >
            <thead>
              <tr role="row">
                {/* <th
                  className="sorting_asc"
                  tabIndex={0}
                  rowSpan={1}
                  colSpan={1}
                  style={{ width: 70 }}
                >
                  ID
                </th> */}
                <th
                  className="sorting"
                  tabIndex={0}
                  rowSpan={1}
                  colSpan={1}
                  style={{ width: 71 }}
                >
                  Name
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  rowSpan={1}
                  colSpan={1}
                  style={{ width: 126 }}
                >
                  Virtual Server
                </th>

                <th tabIndex={0} rowSpan={1} colSpan={1} style={{ width: 66 }}>
                  Protected Resource
                </th>
                <th tabIndex={0} rowSpan={1} colSpan={1} style={{ width: 66 }}>
                  Excluded Resource
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  rowSpan={1}
                  colSpan={1}
                  style={{ width: 75 }}
                >
                  Header Mapping
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  rowSpan={1}
                  colSpan={1}
                  style={{ width: 63 }}
                >
                  Application Hosts
                </th>

                <th
                  className="sorting"
                  tabIndex="0"
                  aria-controls="example2"
                  rowSpan="1"
                  colSpan="1"
                  aria-label=": activate to sort column ascending"
                  style={{ width: "46px" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data.applications.map((application, index) => {
                return (
                  <tr role="row" className="odd" key={index}>
                    {/* <td className="sorting_1">{application.id}</td> */}
                    <td>
                      <span className="text-wrap"> {application.name} </span>
                    </td>
                    <td>
                      <span className="text-nowrap">
                        {findName(
                          props.data.virtualServers,
                          application.virtualServerDefinitionId
                        )}
                      </span>
                    </td>
                    <td>
                      {application.protectedResources.length > 0 && (
                        <Resource
                          resources={application.protectedResources}
                          data={props.data}
                        />
                      )}
                    </td>
                    <td>
                      <span className="text-nowrap">
                        {application.excludedResources != undefined && application.excludedResources.length > 0 && (
                          <Resource
                            resources={application.excludedResources}
                            data={props.data}
                          />
                        )}
                      </span>
                    </td>
                    <td>
                      {findName(
                        props.data.headerMappings,
                        application.headerMappingId
                      )}
                    </td>
                    <td>
                      {findName(
                        props.data.backendServers,
                        application.backendServerDefinitionId
                      )}
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Link
                          to={{
                            pathname: "/application",
                            state: {
                              data: props.data,
                              application: application,
                            },
                          }}
                          className="mr-4"
                        >
                          <i className="las la-pencil-alt scale-2"></i>
                        </Link>
                        <Link
                          to="#"
                          onClick={() => deleteApplication(application.id)}
                        >
                          <i className="las la-trash-alt scale-2 text-danger"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ApplicationList;
