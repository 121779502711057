import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field as FormikField, ErrorMessage } from "formik";
import TextField from "../Utils/TextField";
import CreatableSelectField from "../Utils/CreatableSelectField";
import ResourceSchema from "../Validation/ResourceSchema";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TextFieldIDReadOnly from "../Utils/TextFieldIDReadOnly";

const Resource = (props) => {
  const { close, save, resource } = props;

  const idrsTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
       Enter a unique ID for the Resource
    </Tooltip>
    );

    const namersTooltip = (props) => (
      <Tooltip  id="button-tooltip"  {...props}  >
         Enter a name for the Resource
      </Tooltip>
      );

      const pathrsTooltip = (props) => (
        <Tooltip  id="button-tooltip1"  {...props}  >
           Enter the list of URL path patterns from the Application
        </Tooltip>
        );
        const pathvaluersTooltip = (props) => (
          <Tooltip  id="pathvaluetooltip"  {...props}  >
             Paths must begin with /
          </Tooltip>
          );
  return (
    <Formik
      initialValues={resource}
      enableReinitialize
      validationSchema={ResourceSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        save(values);
      }}
    >
      {({ isSubmitting }) => (
        <>
          <Modal.Header>
            <Modal.Title>Resource</Modal.Title>
            <Button variant="" className="close" onClick={() => close()}>
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Form>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                {/* <OverlayTrigger
                        placement="bottom-end"
                        delay={{ show: 250, hide: 400 }}
                        overlay={idrsTooltip}
                       >  */}
                  <div className="col-sm-6">
                  <TextFieldIDReadOnly name="id" type="text" label="ID" />
                  </div>
                  {/* </OverlayTrigger> */}

                  <OverlayTrigger
                                placement="bottom-end"
                                delay={{ show: 250, hide: 400 }}
                                overlay={namersTooltip}
                                 > 
                  <div className="col-sm-6">
                    <TextField name="name" type="text" label="Name" />
                  </div>
                  </OverlayTrigger>
            
                  <OverlayTrigger
                                 placement="bottom-start"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={pathvaluersTooltip}
                                  > 

                                   
                  <div className="form-group col-md-12">
                    <label className="font-w600">Resource Path</label>
                    <OverlayTrigger
                                             placement="right"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={pathrsTooltip}
                                              > 
                             <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                          </svg>
                 </OverlayTrigger>
                    <FormikField
                      className="form-control dropdown-form-control"
                      component={CreatableSelectField}
                      name="resourcesUri"
                      options={resource.resourcesUri}
                      value={resource.resourcesUri.map((res) => ({
                        value: res,
                        label: res,
                      }))}
                    />
                    <ErrorMessage
                      name={"resourcesUri"}
                      component="div"
                      className="required"
                      />
                  </div>
                  </OverlayTrigger>
               
                </div>
              </div>
            </div>

            <Modal.Footer>
              <Button
                onClick={() => close()}
                className="btn btn-secondary btn-sm light"
              >
                Cancel
              </Button>
              <Button type="submit" className="btn btn-sm">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Resource;