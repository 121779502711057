import React, { useState, useEffect, useRef } from "react";
import {Button , Tab } from "react-bootstrap";
import axios from "axios";
import agent_template from "../Utils/agent_template.json";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import loading from "../../images/loading.gif";
import { useAuth } from "react-oidc-context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const instanceTooltip = (props) => (
  <Tooltip id="choosefiletooltip" {...props}>
      Authenion Instance hostname to retrieve the Configurations
  </Tooltip>
);
const FetchTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
      Fetch your existing configuration from the Authenion Server
  </Tooltip>
);
const Importurl =(props) =>{
  //const [instance, setInstance] = useState("");
  const instanceRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [openIdSettings, setOpenIdSettings] = useState({
    ...agent_template.tokenProvider,
    ...agent_template.cookieSettings,
  });
  
  const [statusMessage, setStatusMessage] = useState('');

  const handleClick = async () => {
    setIsLoading(true);
  
    try {
      const response = await axios.get(`https://${instanceRef.current.value}:8090/ssolibraryagent/configurations/import`, {
        headers: {
          'Authorization': `Bearer ${props.token}`
        }
      });
  
      if (response.status === 200) {
        toast.success('Import successful !',{ position: 'top-center'});
        /* window.location.reload(); */
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Delay for 2 seconds
      }
  
      const values = response.data;
  
      if (values) {
        localStorage.setItem('data', JSON.stringify(values));
        setData(values);
        setOpenIdSettings({
          ...values.tokenProvider,
          ...values.cookieSettings,
        });
      }
  
      setStatusMessage('Import successful !');
    } catch (error) {
      setStatusMessage('Import failed. Please try again.');
      toast.error('Import failed. Please try again.',{ position: 'top-center'});
    } finally {
      setIsLoading(false);
    }
  };  
  return (
    
  <div>
     <div>
    <ToastContainer />
   </div>
      <input className="Authenion-form-control" type="text" ref={instanceRef}/>
       <div>
      {isLoading ? (
        <img style={{ height: "30px" }} src={loading} alt="" />
      ) : (
        <OverlayTrigger
                                             placement="right"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={FetchTooltip}
                                              >
        <Button
          style={{ marginTop: "10px", marginLeft: "-9px" }}
          className="btn btn-edit text-nowrap"
          onClick={handleClick}
        >
          Fetch
        </Button>
        </OverlayTrigger>
      )}
    </div>
  </div>
  );

}

const Import = (props) => {
  const [data, setData] = useState(agent_template);
  const [accessToken, setAccessToken] = useState(0);
  const auth = useAuth();
  

  useEffect(() => {
	
    let accessToken = auth.user?.access_token;
    setAccessToken(accessToken);
    
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);
  }, []);
  return (
    <>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="applications">
          <div className="row">
            <div className="col-xl-12">
                <div id="All" className="tab-pane">
                  <div className="table-responsive">
                    <div
                      id="applications_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                    <div className="col-sm-6 ">
                        <div className="input-field-redux form-group">
                          <label className="text-black font-w600" style={{marginLeft:"-15px"}}>
                          Authenion Instance
                          </label>
                          <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 400 }}
                               overlay={instanceTooltip}
                               >
                             <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg" style={{color:"#000"}} width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                             </svg>
                           </OverlayTrigger>
						   <Importurl token={accessToken}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default Import;