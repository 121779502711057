import React, { useState, useEffect } from "react";
import {Card, Tab } from "react-bootstrap";
import loading from "../../images/loading.gif";
import axios from "axios";
import agent_template from "../Utils/agent_template.json";
import { useAuth } from "react-oidc-context";

var InstanceStatus = (props) => {
  const [status, setStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState(false);
  const urlPath = "/ssolibraryagent/configurations/healthcheck";

  useEffect(() => {
    setStatus("Not Started");
    setTimeout(() => sync(), 500);
  }, []);

  const sync = () => {
    axios
      .get(props.host + urlPath, {
        headers: {
        'Authorization' : `Bearer ${props.token}`
        }
      })
      .then(function (response) {
        setStatus("Success");
      })
      .catch(function (error) {
        setStatusMsg(error.message);
        setStatus("Error");
      });
  };

  return (
    <tr role="row" className="odd" key={props.index}>
      <td>
        <span className="text-nowrap">{props.host}</span>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {status === "Success" && (
            <span className="badge badge-rounded badge-success">Success</span>
          )}

          {status === "Error" && (
            <>
              
              <span className="badge badge-rounded badge-danger">
                {" "}
                {statusMsg}{" "}
              </span>
            </>
          )}

          {status === "Not Started" && (
            <img style={{ height: "30px" }} src={loading} alt="" />
          )}
        </div>
      </td>
    </tr>
  );
};

var LicenseStatus  = (props) =>  {
    const [status, setStatus] = useState(false);
	const [statusMsg, setStatusMsg] = useState(false);
  const [field,setField]=useState([])
	const urlPath = "/ssolibraryagent/configurations/healthcheck";
  const renderList = field.map((item) =>item);
	
	useEffect(() => {
		setStatus("Not Started");
		setTimeout(() => sync(), 500);
	}, []);
	
	const sync = () => {
    axios
      .get( props.host + urlPath, {
        headers: {
        'Authorization' : `Bearer ${props.token}`
        }
      })
      .then(function (response) {
        setStatus("Success");  

        const values =response.data.replace(/[^\w ',', '.' =:-]/g, '')
                const arrayValues=values.split(",");
                setField(arrayValues);
               setStatusMsg(values);
	  })
      .catch(function (error) {
        setStatusMsg(error.message);
        setStatus("Error");
      });
      
	};
    
    return(
		<div className="mb-2 align-items-center justify-content-between" style={{marginLeft:"30px"}}>
          <div className="mb-3 mr-3">
             <label className="licInfo" >
               Authenion License Information
             </label>
          </div>
		  {status === "Not Started" && (
            <img style={{ height: "30px" }} src={loading} alt="" />
          )}
		  
		  {status === "Error" && (
            <>
              
              <span className="badge badge-rounded badge-danger">
                {" "}
                {statusMsg}{" "}
              </span>
            </>
          )}
		  {status === "Success" && (
        <div style={{color:"#000",fontSize:"14px",wordBreak:"break-all"}}>
        <table className="table">
      
      <tbody>
        <tr>
          <td style={{width:"180px"}}>Organization</td>
          <td style={{width:"70px"}}>:</td>
          <td>{(renderList[0])}</td>
        </tr>
        <tr>
          <td>Expiration date</td>
          <td>:</td>
          <td>{(renderList[1])}</td>
        </tr>
        <tr>
          <td>Licensetype</td>
          <td>:</td>
          <td>{(renderList[2])}</td>
        </tr>
      </tbody>
    </table>
        </div>
          )}
          
        </div>
    );
};

const Status = (props) => {
  const [data, setData] = useState(agent_template);
  const [hostsCount, setHostsCount] = useState(0);
  const [accessToken, setAccessToken] = useState(0);
  const auth = useAuth();
  useEffect(() => {
	
	let accessToken = auth.user?.access_token;
    setAccessToken(accessToken);
    
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);

    let hostsCount =
      data &&
      data.tokenProvider.syncAgentHostNames &&
      data.tokenProvider.syncAgentHostNames.length > 0
        ? data.tokenProvider.syncAgentHostNames.length
        : 0;
    setHostsCount(hostsCount);
  }, []);

  return (
    <>
      {/* <!-- Nav tabs --> */}
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="applications">
          <Card style={{ marginBottom: 0 }}>
            <Card.Body style={{ paddingBottom: 0 }}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                <div className="mb-3 mr-3">
                  <h6 className="fs-16 text-black font-w600 mb-0">
                    {hostsCount} Total Instances
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>

          {hostsCount > 0 && (
            <div id="All" className="tab-pane">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display dataTablesCard dataTable no-footer"
                    role="grid"
                    aria-describedby="example2_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: 70 }}
                        >
                          Authenion Instances
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label=": activate to sort column ascending"
                          style={{ width: "46px" }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.tokenProvider.syncAgentHostNames.map(
                        (hostName, index) => {
                          return (
                            <InstanceStatus
                              host={hostName}
                              index={index}
                              token={accessToken} 
                              key="InstanceStatus"
                            />
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
		  )}
		 {hostsCount > 0 && <LicenseStatus host={data.tokenProvider.syncAgentHostNames[0]} token={accessToken} />}
        </Tab.Container>
      </div>
    </>
  );
};

export default Status;