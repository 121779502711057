import React, { useState, useEffect } from "react";
import CreatableSelectScroll from "../Utils/CreatableSelectScroll";
import CreatableSelectField from "../Utils/CreatableSelectField";
import { Formik, Form, Field as FormikField, ErrorMessage } from "formik";
import { Card } from "react-bootstrap";
import TextField from "../Utils/TextField";
import ConfigSchema from "../Validation/ConfigSchema";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const OpenIdSettings = (props) => {
  const [ , setData] = useState(props.data);
  const [openIdSettings,] = useState(props.openIdSettings);

  useEffect(() => {
    setData(props.data);
  },[props.data]);

  console.log(JSON.stringify(openIdSettings));

  const notify = () => {
    toast.success("Settings have been saved successfully!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };

  const renderTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        OIDC Token Provider's Authorization Endpoint
    </Tooltip>
  );

  const tokenurlTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        OIDC Token Provider's Token endpoint
    </Tooltip>
  );

  const logouturlTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        OIDC Token Provider's Logout URL
    </Tooltip>
  );

  const jwkurlTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        OIDC Token Provider's JWKS endpoint for token validation
    </Tooltip>
  );

  const issuerTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        OIDC Token Provider's Issuer included in the token 
    </Tooltip>
  );
  
  const userinfourlTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
       OIDC token providers UserInfo endpoint to retrieve consented claims 
    </Tooltip>
  );
  const audienceTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Excepted value to be included in the OIDC token 
    </Tooltip>
  );
  const clientidTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Unique Identifier for Authenion Client
    </Tooltip>
  );
  const clientsecretTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Client Secret for the Client ID
    </Tooltip>
  );
  const scopeTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Scopes requested to return user attributes
    </Tooltip>
  );
  const claimsTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        User details returned from the OIDC token provider
    </Tooltip>
  );
  const authenioninstanceTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Authenion Instances to receive the Configuration
    </Tooltip>
  );
  const cookiedomainTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Authenion's Domain
    </Tooltip>
  );
  const sessionTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
        Time in seconds that the session will be active
    </Tooltip>
  );

  return (
    <Formik
      initialValues={openIdSettings}
      enableReinitialize={true}
      validationSchema={ConfigSchema}
      onSubmit={(values, { setSubmitting }) => {

        let data = props.data;
        const cookieDomain = values.cookieDomain;
        const sessionDuration = values.sessionDuration;
        data.cookieSettings = { cookieDomain, sessionDuration };
       
        let newTokenProvider = {
          ...values,
          claims: values.claims.map((obj) => obj.value),
          syncAgentHostNames: values.syncAgentHostNames.map((obj) => obj.value)
        };

        delete newTokenProvider.cookieDomain;
        delete newTokenProvider.sessionDuration;
       
        data.tokenProvider = newTokenProvider;
        localStorage.setItem("data", JSON.stringify(data));
        setData(data);
        setSubmitting(false);
        notify();
       window.location.replace("/");
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Card style={{ marginBottom: 0 }}>
            <Card.Body style={{ paddingBottom: 0 }}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                <div className="mb-3 mr-3">
                  <h6 className="fs-18 text-black font-w600 mb-60">
                  OpenID Connect Provider  - Settings
                  </h6>
                </div>
              </div>

              <div className="row">
                              <OverlayTrigger
                                  placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={renderTooltip}
                   >
                <div className="col-sm-6">
                  <TextField
                    name="authorizationUrl"
                    type="text"
                    label="Authorization URL"
                  />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={tokenurlTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="tokenUrl" type="text" label="Token URL" />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={logouturlTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="logoutUrl" type="text" label="Logout URL" />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={jwkurlTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="jwksUrl" type="text" label="JWKS URL" />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={issuerTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="issuer" type="text" label="Issuer" />
                </div>
                </OverlayTrigger>

                 <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={userinfourlTooltip}
                   >
                <div className="col-sm-6">
                  <TextField
                    name="userInfoUrl"
                    type="text"
                    label="UserInfo URL"
                  />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={audienceTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="audience" type="text" label="Audience" />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={clientidTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="clientId" type="text" label="Client Id" />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={clientsecretTooltip}
                   >
                <div className="col-sm-6">
                  <TextField
                    name="clientSecret"
                    type="password"
                    label="Client Secret"
                  />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={scopeTooltip}
                   >
                <div className="col-sm-6">
                  <TextField name="scope" type="text" label="Scope" />
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={claimsTooltip}
                   >
                <div className="col-sm-6">
                  <div className="input-field-redux form-group">
                    <label className="font-w600">Claims</label>
                    <div>
                      <FormikField
                        className="form-control dropdown-form-control"
                        component={CreatableSelectScroll}
                        name="claims"
                        value={
                          openIdSettings.claims
                            ? openIdSettings.claims.map((res) => ({
                                value: res,
                                label: res,
                              }))
                            : []
                        }
                      />
                      <ErrorMessage
                        name={"claims"}
                        component="div"
                        className="required"
                      />
                    </div>
                  </div>
                </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom-end"
                   delay={{ show: 250, hide: 400 }}
                   overlay={authenioninstanceTooltip}
                   >
                <div className="col-sm-6">
                  <div className="input-field-redux form-group">
                    <label className="font-w600">Authenion Instances</label>
                    <div>
                      <FormikField
                        className="form-control dropdown-form-control"
                        component={CreatableSelectScroll}
                        name="syncAgentHostNames"
                        value={
                          openIdSettings.syncAgentHostNames
                            ? openIdSettings.syncAgentHostNames.map((res) => ({
                                value: res,
                                label: res,
                              }))
                            : []
                        }
                      />
                      <ErrorMessage
                        name={"syncAgentHostNames"}
                        component="div"
                        className="required"
                        />
                    </div>
                  </div>
                </div>
                </OverlayTrigger>
              </div>
            </Card.Body>
          </Card>

         <Card style={{ marginBottom: 0 }}>
                    <Card.Body style={{ paddingBottom: 0 }}>
                        <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                          <div className="mb-3 mr-3">
                            <h6 className="fs-18 text-black font-w600 mb-60">
                              Cookie Settings
                            </h6>
                          </div>
                        </div>

                       <div className="row">
                       <OverlayTrigger
                          placement="bottom-end"
                           delay={{ show: 250, hide: 400 }}
                           overlay={cookiedomainTooltip}
                           >
                          <div className="col-sm-6">
                            <TextField
                              name="cookieDomain"
                              type="text"
                              label="Cookie Domain"
                            />
                          </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                          placement="bottom-end"
                           delay={{ show: 250, hide: 400 }}
                           overlay={sessionTooltip}
                           >
                          <div className="col-sm-6">
                            <TextField
                              name="sessionDuration"
                              type="string"
                              label="Session Duration"
                            />
                          </div>
                          </OverlayTrigger>
                        </div>
                      </Card.Body>
          </Card>


          <Card>
            <Card.Body>
               

                <div className="col-sm-6">
                  <button type="submit" className="next btn btn-edit">
                    Save
                  </button>
                  <ToastContainer autoClose={2000} transition={Flip} />
                </div>

            </Card.Body>
          </Card>


        </Form>
      )}
    </Formik>
  );
};

export default OpenIdSettings;
