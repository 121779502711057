import {
  Formik,
  Form,
  Field as FormikField,
  ErrorMessage,
  useField,
} from "formik";
import TextField from "../Utils/TextField";
import CreatableSelectField from "../Utils/CreatableSelectField";
import { useHistory } from "react-router-dom";
import ApplicationSchema from "../Validation/ApplicationSchema";
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import VirtualServer from "./VirtualServer";
import BackendServer from "./BackendServer";
import Resource from "./Resource";
import HeaderMapping from "./HeaderMapping";
import { useLocation } from "react-router-dom";
import applicationTemplate from "../Utils/application_template.json";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import agent_template from "../Utils/agent_template.json";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TextFieldIDReadOnly from "../Utils/TextFieldIDReadOnly";
import CreatableSelectScroll from "../Utils/CreatableSelectScroll";

const idTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
      Enter a unique ID for the Application
  </Tooltip>
);

const nameTooltip = (props) => (
  <Tooltip id="button-tooltip"  {...props}  >
      Enter a Name for the Application
  </Tooltip>
);

const virtualTooltip = (props) => (
  <Tooltip id="button-tooltip1" style={{backgroundcolor:"white"}} {...props}  >
      Virtual Server Names for the Application
  </Tooltip>
);

const headermappingTooltip = (props) => (
  <Tooltip id="button-tooltip1"  {...props}  >
      Authenticated user attributes are made available as HTTP request headers
  </Tooltip>
);
const protectedTooltip = (props) => (
  <Tooltip id="button-tooltip1"  {...props}  >
       Resources of the Application if it requires Authentication
  </Tooltip>
);

const excludedTooltip = (props) => (
  <Tooltip id="button-tooltip1"  {...props}  >
      Unprotected resources of the Application 
  </Tooltip>
);

const applicationTooltip = (props) => (
  <Tooltip id="button-tooltip1"  {...props}  >
     Server where the target application is installed
  </Tooltip>
);

const authenioninstanceTooltip = (props) => (
  <Tooltip id="button-tooltip3"  {...props}  >
      Authenion Instances to receive the configuration
  </Tooltip>
);
const enableHostTooltip = (props) => (
<Tooltip id="button-tooltip1" {...props}  >
      Host header value will be set for all requests being sent
  </Tooltip>
);


function SelectField(props) {
  const [, state, { setValue, setTouched }] = useField(props.field.name);

  useEffect(() => {
    setValue(props.value);
  }, []);

  // value is an array now
  const onChange = (value) => {
    if (value === null) {
      value = [];
    }
    setValue(value);
  };

  // use value to make this a  controlled component
  // now when the form receives a value for 'campfeatures' it will populate as expected
  return (
    <Select
      className="form-control dropdown-form-control"
      {...props}
      value={state?.value}
      onChange={onChange}
      onBlur={setTouched}
    />
  );
}

const Application = (props) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [inputValues, setInputValues] = useState({
  overrideSecurityHeaders:false,
  accessControlAllowHeaders:"",
  accessControlAllowMethods: "",
  referrerPolicy: "",
  strictTransportSecurity: "",
  xContentTypeOptions: "",
  xFrameOptions: "",
  xXSSProtection: "",
  contentSecurityPolicy: ""});
  const history = useHistory(); // Initialize useHistory
  const handleCheckboxChange = (e) => {
    setIsCheckboxEnabled(e.target.checked)
  };
 const handleInputChange = (event,inputIdentifier) => {
    setInputValues({
      ...inputValues,
      [inputIdentifier]: event.target.value,
      });
    };

  const handleClick = () => {
    setShowAdvanced(!showAdvanced);
  };
  
  const location = useLocation();
  const appl =
    location.state.application.id !== undefined
      ? location.state.application
      : applicationTemplate;
  const data = location.state.data;

  const appUUID = appl.id === '' ? uuidv4() : appl.id;
  

  let [, setId] = useState("");
  let [, setName] = useState("");
  let [, setSyncAgentHostNames] = useState("");
  const [application, setApplication] = useState({});
  const [virtualServers, setVirtualServers] = useState(
    agent_template.virtualServers
  );
  const [backendServers, setBackendServers] = useState(
    agent_template.backendServers
  );
  const [headerMappings, setHeaderMappings] = useState(
    agent_template.headerMappings
  );
  const [resources, setResources] = useState(agent_template.resources);

  useEffect(() => {
    setApplication({...appl, id: appUUID });
    setId(appUUID);
    setName(appl.name);
    setSyncAgentHostNames(appl.syncAgentHostNames);
    setInputValues({
      overrideSecurityHeaders: appl.overrideSecurityHeaders,
      accessControlAllowHeaders: appl.accessControlAllowHeaders,
      accessControlAllowMethods: appl.accessControlAllowMethods,
      referrerPolicy: appl.referrerPolicy,
      strictTransportSecurity: appl.strictTransportSecurity,
      xContentTypeOptions: appl.xContentTypeOptions,
      xFrameOptions: appl.xFrameOptions,
      xXSSProtection: appl.xXSSProtection,
      contentSecurityPolicy: appl.contentSecurityPolicy,
    });
    setVirtualServers(
      data.virtualServers[0] && data.virtualServers[0].id !== ""
        ? data.virtualServers.map((vs) => ({ value: vs.id, label: vs.name }))
        : []
    );
    setBackendServers(
      data.backendServers[0] && data.backendServers[0].id !== ""
        ? data.backendServers.map((vs) => ({ value: vs.id, label: vs.name }))
        : []
    );
    setHeaderMappings(
      data.headerMappings[0] && data.headerMappings[0].id !== ""
        ? data.headerMappings.map((vs) => ({ value: vs.id, label: vs.name }))
        : []
    );
    setResources(
      data.resources[0] && data.resources[0].id !== ""
        ? data.resources.map((vs) => ({ value: vs.id, label: vs.name }))
        : []
    );

    
  }, [location]);

  const notify = () => {
    toast.success("Application has been saved successfully!", {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
    });
  };

  const [virtualServerModal, setVirtualServerModal] = useState(false);
  const [backendServerModal, setBackendServerModal] = useState(false);
  const [resourceModal, setResourceModal] = useState({});
  const [headerMappingModal, setHeaderMappingModal] = useState(false);
  
  let closeVirtualServerModal = () => {
    setVirtualServerModal(false);
  };

  let saveVirtualServer = (values) => {
    const intPort = parseInt(values.port, 10);
    values.port = intPort;

    let dataVirtualServers = data.virtualServers;
    const existingvirtualServer = dataVirtualServers.find(
      (virtualServer) =>
      virtualServer.name === values.name && virtualServer.id !== values.id
    );
  
    if (existingvirtualServer) {
      alert("Error: VirtualServer name already exists.");
      return;
    }

    let objIndex = dataVirtualServers.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );
    if (objIndex >= 0) {
      dataVirtualServers[objIndex] = values;
    } else {
      dataVirtualServers.push(values);
    }

    setVirtualServers([
      ...virtualServers,
      { label: values.name, value: values.id },
    ]);

    application.virtualServerDefinitionId = values.id;
    setApplication(application);

    setVirtualServerModal(false);
    localStorage.setItem("data", JSON.stringify(data));
  };

  let closeBackendServerModal = () => {
    setBackendServerModal(false);
  };

  let saveBackendServer = (values) => {
    let dataBackendServers = data.backendServers;
    const existingBackendServer = dataBackendServers.find(
      (backendServer) =>
      backendServer.name === values.name && backendServer.id !== values.id
    );
  
    if (existingBackendServer) {
      alert("Error: BackendServer name already exists.");
      return;
    }

    let urls = values.urls.map((obj) => obj.value);
    values.urls = urls;

    let objIndex = dataBackendServers.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );

    if (objIndex >= 0) {
      dataBackendServers[objIndex] = values;
    } else {
      dataBackendServers.push(values);
    }

    application.backendServerDefinitionId = values.id;
    setApplication(application);

    setBackendServers([
      ...backendServers,
      { label: values.name, value: values.id },
    ]);
    setBackendServerModal(false);
    localStorage.setItem("data", JSON.stringify(data));
  };

  let closeResourceModal = () => {
    setResourceModal({ source: "", open: false });
  };

  let saveResource = (values) => {
    let dataResources = data.resources;
     const existingResource = dataResources.find(
      (resource) =>
        resource.name === values.name && resource.id !== values.id
    );
  
    if (existingResource) {
      alert("Error: Resource name already exists.");
      return;
    }

    let resourcesUri = values.resourcesUri.map((obj) => obj.value);
    values.resourcesUri = resourcesUri;

    let objIndex = dataResources.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );

    if (objIndex >= 0) {
      dataResources[objIndex] = values;
    } else {
      dataResources.push(values);
    }

    setResources([...resources, { label: values.name, value: values.id }]);
    setResourceModal({ source: "", open: false });
    localStorage.setItem("data", JSON.stringify(data));
  };

  

  let findName = (list, id) => {
    let obj = list.find((obj) => obj.value === id);
    return obj ? obj.label : "";
  };

  let closeHeaderMappingModal = () => {
    setHeaderMappingModal(false);
  };

  let saveHeaderMapping = (values) => {
    const transformedClaimMappings = {};
   values.claimMappings.forEach((claimMapping) => {
      if (claimMapping.headerName !== "") {
        transformedClaimMappings[claimMapping.headerName] =
          claimMapping.headerValue;
      }
    });
  
    const updatedHeaderMapping = {
      id: values.id,
      name: values.name,
      claimMappings: transformedClaimMappings,
    };

    let dataHeaderMappings = data.headerMappings;
    const existingheaderMappings = dataHeaderMappings.find(
      (headerMapping) =>
        headerMapping.name === values.name && headerMapping.id !== values.id
    );
  
    if (existingheaderMappings) {
      alert("Error: HeaderMapping name already exists.");
      return;
    }

        let objIndex = dataHeaderMappings.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );

    if (objIndex >= 0) {
      dataHeaderMappings[objIndex] = updatedHeaderMapping;
    } else {
      dataHeaderMappings.push(updatedHeaderMapping);
    }

    application.headerMappingId = values.id;
    setApplication(application);

    setHeaderMappings([
      ...headerMappings,
      { label: values.name, value: values.id },
    ]);
    setHeaderMappingModal(false);
    localStorage.setItem("data", JSON.stringify(data));
  };
  const defaultVirtualServer = {
    id: uuidv4(),
    name: "",
    host: "",
    port: "",
    useSSL: false,
  };

  const defaultBackendServer = {
    id: uuidv4(),
    name: "",
    urls: [],
    useSSL: false,
  };

  const defaultHeaderMapping = {
    id: uuidv4(),
    name: "",
    claimMappings: [{"headerName": "", "headerValue": ""}],
  };

  const defaultResource = {
    id: uuidv4(),
    name: "",
    resourcesUri: [],
  };
  return (
    <>
      {application.id !== undefined && (
        <>
          <Formik
            initialValues={application}
            enableReinitialize={true}
            validationSchema={ApplicationSchema}
            onSubmit={(values, { setSubmitting }) => {
              let newApplication = {
                id: values.id,
                name: values.name,
                virtualServerDefinitionId:
                  values.virtualServerDefinitionId.value,
                backendServerDefinitionId:
                  values.backendServerDefinitionId.value,
                headerMappingId: values.headerMappingId.value,
                protectedResources: values.protectedResources.map(
                  (obj) => obj.value
                ),
                excludedResources: values.excludedResources ? values.excludedResources.map(
                  (obj) => obj.value) : [],
                syncAgentHostNames: values.syncAgentHostNames.map(
                  (obj) => obj.value
                ),
                enabled: values.enabled,
                enableHostHeader: values.enableHostHeader,
                overrideSecurityHeaders:inputValues.overrideSecurityHeaders,
                accessControlAllowHeaders:inputValues.accessControlAllowHeaders,
                accessControlAllowMethods:inputValues.accessControlAllowMethods,
                referrerPolicy:inputValues.referrerPolicy,
                strictTransportSecurity:inputValues.strictTransportSecurity,
                xContentTypeOptions:inputValues.xContentTypeOptions,
                xFrameOptions:inputValues.xFrameOptions,
                xXSSProtection:inputValues.xXSSProtection,
                contentSecurityPolicy:inputValues.contentSecurityPolicy,
              };

              // Check if the name already exists in the applications array
              const isNameDuplicate = data.applications.find(
              (app) => app.id !== values.id && app.name === values.name
                 );

              if (isNameDuplicate) {
              alert("Error: Application with this name already exists!");
              // toast.error("Application with this name already exists!");
               setSubmitting(false);
                return;
                }

              let applications = data.applications;
              let objIndex = applications.findIndex(
                (obj) => obj.id === values.id || obj.id === ""
              );

              if (objIndex >= 0) {
                applications[objIndex] = newApplication;
              } else {
                applications.push(newApplication);
              }
              localStorage.setItem("data", JSON.stringify(data));
              setSubmitting(false);
              notify();
              history.push("/");
              
            }}
          >
            {({ errors, isSubmitting }) => (
              <Form>
                <div className="card-body">
                  <div className="basic-form">
                    <div className="row">
                               {/* <OverlayTrigger
                                     placement="bottom-end"
                                     delay={{ show: 250, hide: 400 }}
                                     overlay={idTooltip}
                                  >  */}
                      <div className="col-sm-6">
                      <TextFieldIDReadOnly name="id" type="text" label="ID" />
                    </div>
                               {/* </OverlayTrigger> */}

                               <OverlayTrigger
                                     placement="bottom-end"
                                     delay={{ show: 250, hide: 400 }}
                                     overlay={nameTooltip}
                                     > 
                      <div className="col-sm-6">
                        <TextField name="name" type="text" label="Name" />
                      </div>
                             </OverlayTrigger>

                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "30px" }}
                      >
                        <label className="font-w600">Virtual Server</label>
                        <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 400 }}
                               overlay={virtualTooltip}
                               >
                             <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                             </svg>
                       </OverlayTrigger>
                        <div className="input-group">
                          <FormikField
                            className="form-control dropdown-form-control"
                            isMulti={false}
                            component={SelectField}
                            name="virtualServerDefinitionId"
                            value={{
                              value: application.virtualServerDefinitionId,
                              label: findName(
                                virtualServers,
                                application.virtualServerDefinitionId
                              ),
                            }}
                            options={virtualServers}
                          />

                          <div className="input-group-append">
                            <button
                              className="btn btn-edit btn-sm"
                              type="button"
                              onClick={() => setVirtualServerModal(true)}
                            >
                              <i className="fa fa-plus" />
                            </button>
                          </div>
                        </div>

                        <ErrorMessage
                          name={"virtualServerDefinitionId.label"}
                          component="div"
                          className="required"
                          
                        />
                      </div>

                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "30px" }}
                      >
                        <label className="font-w600">Header Mapping</label>
                        <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 400 }}
                               overlay={headermappingTooltip}
                               >
                              <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                              </svg>
                       </OverlayTrigger>
                        <div className="input-group">
                          <FormikField
                            isMulti={false}
                            component={SelectField}
                            name="headerMappingId"
                            value={{
                              value: application.headerMappingId,
                              label: findName(
                                headerMappings,
                                application.headerMappingId
                              ),
                            }}
                            options={headerMappings}
                          />

                          <div className="input-group-append">
                            <button
                              className="btn btn-edit btn-sm"
                              type="button"
                              onClick={() => setHeaderMappingModal(true)}
                            >
                              <i className="fa fa-plus" />
                            </button>
                          </div>
                        </div>
                        <ErrorMessage
                          name={"headerMappingId.label"}
                          component="div"
                          className="required"
                      
                        />
                      </div>

                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "30px" }}
                      >
                        <label className="font-w600">Protected Resource</label>
                        <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 400 }}
                               overlay={protectedTooltip}
                               >
                               <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                               <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                               </svg>
                               </OverlayTrigger>
                        <div className="input-group">
                          <FormikField
                            isMulti={true}
                            className="form-control dropdown-form-control"
                            component={SelectField}
                            name={"protectedResources"}
                            value={
                              application.protectedResources.length === 0
                                ? []
                                : application.protectedResources.map((res) => ({
                                    value: res,
                                    label: findName(resources, res),
                                  }))
                            }
                            options={resources}
                          />

                          <div className="input-group-append">
                            <button
                              className="btn btn-edit btn-sm"
                              type="button"
                              onClick={() =>
                                setResourceModal({
                                  source: "protected",
                                  open: true,
                                })
                              }
                            >
                              <i className="fa fa-plus" />
                            </button>
                          </div>
                        </div>
                        <ErrorMessage
                          name={"protectedResources"}
                          component="div"
                          className="required"
                         
                          
                        />
                      </div>
                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "30px" }}
                      >
                        <label className="font-w600">Excluded Resource</label>
                        <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 400 }}
                               overlay={excludedTooltip}
                               >
                               <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                               <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                             </svg>
                             </OverlayTrigger>
                        <div className="input-group">
                          <FormikField
                            isMulti={true}
                            className="form-control dropdown-form-control"
                            component={SelectField}
                            name={"excludedResources"}
                            value={
                              application.excludedResources
                                ? application.excludedResources.map((res) => ({
                                    value: res,
                                    label: findName(resources, res),
                                  }))
                                : null
                            }
                            options={resources}
                          />

                          <div className="input-group-append">
                            <button
                              className="btn btn-edit btn-sm"
                              type="button"
                              onClick={() =>
                                setResourceModal({
                                  source: "excluded",
                                  open: true,
                                })
                              }
                            >
                              <i className="fa fa-plus" />
                            </button>
                          </div>
                        </div>
                        <ErrorMessage
                          name={"excludedResources"}
                          component="div"
                          className="required"
                          
                        />
                      </div>

                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "30px" }}
                      >
                        <label className="font-w600">Application Host</label>
                        <OverlayTrigger
                               placement="right"
                               delay={{ show: 250, hide: 400 }}
                               overlay={applicationTooltip}
                               > 
                               <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                               <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                             </svg>
                             </OverlayTrigger>
                        <div className="input-group">
                          <FormikField
                            isMulti={false}
                            className="form-control dropdown-form-control"
                            component={SelectField}
                            name="backendServerDefinitionId"
                            value={{
                              value: application.backendServerDefinitionId,
                              label: findName(
                                backendServers,
                                application.backendServerDefinitionId
                              ),
                            }}
                            options={backendServers}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-edit btn-sm"
                              type="button"
                              onClick={() => setBackendServerModal(true)}
                            >
                              <i className="fa fa-plus" />
                            </button>
                          </div>
                        </div>

                        <ErrorMessage
                          name={"backendServerDefinitionId.label"}
                          component="div"
                          className="required"
                        
                        />
                      </div>

                      <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={authenioninstanceTooltip}
                               > 
                      <div className="col-sm-6 ">
                        <div className="input-field-redux form-group">
                          <label className="font-w600">
                          Authenion Instances
                          </label>
                          <div>
                            <FormikField
                              className="form-control dropdown-form-control"
                              component={CreatableSelectScroll}
                              name="syncAgentHostNames"
                              value={
                                application.syncAgentHostNames
                                  ? data.tokenProvider.syncAgentHostNames.map((res) => ({
                                      value: res,
                                      label: res,
                                    }))
                                  : []
                              }
                            />
                            
                           <ErrorMessage
                              name={"syncAgentHostNames"}
                              component="div"
                              className="required"
                            
                            />
                          </div>
                        </div>
                      </div>
                      </OverlayTrigger>

                      <div className="col-sm-6">
                        <label className="font-w600">Enable?</label>
                        <div>
                          <FormikField
                            className="custom-checkbox mb-3 checkbox-warning check-xl"
                            type="checkbox"
                            name="enabled"
                
                          />
                        </div>
                      </div>
                      
                      <div className="col-sm-6">
                        <label className="font-w600">Enable Host Header</label>
                        <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={enableHostTooltip}
                                 >
                                 <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                 <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                 </svg>
                                 </OverlayTrigger>
                        <div>
                        <FormikField
                        className="custom-checkbox mb-3 checkbox-warning check-xl"
                        type="checkbox"
                        name="enableHostHeader"
                      />
                        </div>
                      </div>
                      
                      

                      <div className="col-sm-61">
                          {showAdvanced && (
                                   <div>
                                    <div>
                                      <label style={{marginTop:"15px",marginLeft:"14px"}} className="font-w600">Configure Security Headers for your Application </label>
                                      </div>
                                   <div>
                            <FormikField
                            className="custom-checkbox mb-3 checkbox-warning check-xl"
                            type="checkbox"
                            name="overrideSecurityHeaders"
                            checked={isCheckboxEnabled}
                            onChange={handleCheckboxChange}
                            // onChange={(event) => handleCheckboxChange(event, 'overrideSecurityHeaders')}
                            value={inputValues.overrideSecurityHeaders}
                            style={{marginLeft:"15px"}}
                             />
                              <label style={{ marginLeft:"10px",marginTop:"10px"}} className="font-w60011" >
                              Override Defaults</label>
                            </div>
                                    
                        <div className="advanced-file-options">
                                          <div className="col-sm-6">
                                               <label className="font-w60011">
                                                Access-Control-Allow-Headers
                                                </label>
                                                <input
                                                  name="accessControlAllowHeaders"
                                                  type="text"
                                                  className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                  onChange={(event) => handleInputChange(event, 'accessControlAllowHeaders')}
                                                   readOnly={!isCheckboxEnabled}
                                                   value={inputValues.accessControlAllowHeaders}
                                                
                                                 />
                                           </div>
                                           
                                           <div className="col-sm-6">
                                                 <label className="font-w60011">
                                                  Access-Control-Allow-Methods
                                                  </label>
                                               <input
                                                     name="accessControlAllowMethods"
                                                      type="text"
                                                      className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                      onChange={(event) => handleInputChange(event, 'accessControlAllowMethods')}
                                                      value={inputValues.accessControlAllowMethods}
                                                      readOnly={!isCheckboxEnabled}
                                                 />
                                            </div>
                                               

                                            <div className="col-sm-6">
                                                   <label className="font-w60011">
                                                    Referrer-Policy
                                                     </label>
                                                 <input
                                                        name="referrerPolicy"
                                                        type="text"
                                                       className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                       onChange={(event) => handleInputChange(event, 'referrerPolicy')}
                                                      value={inputValues.referrerPolicy}
                                                        readOnly={!isCheckboxEnabled}
                                                  />
                                            </div>
                                                  
                                            <div className="col-sm-6">
                                                 <label className="font-w60011">
                                                  Strict-Transport-Security
                                                  </label>
                                                  <input
                                                       name="strictTransportSecurity"
                                                       type="text"
                                                       className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                       onChange={(event) => handleInputChange(event, 'strictTransportSecurity')}
                                                       value={inputValues.strictTransportSecurity}
                                                       readOnly={!isCheckboxEnabled}
                                                  />
                                            </div>
                                                 
                                              <div className="col-sm-6">
                                                    <label className="font-w60011" >
                                                  X-Content-Type-Options
                                                  </label>
                                                  <input
                                                         name="xContentTypeOptions"
                                                         type="text"
                                                        className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                        onChange={(event) => handleInputChange(event, 'xContentTypeOptions')}
                                                        value={inputValues.xContentTypeOptions}
                                                         readOnly={!isCheckboxEnabled}
                                                  />
                                              </div>
                                                  
                                                <div className="col-sm-6">
                                                        <label className="font-w60011">
                                                        X-Frame-Options
                                                        </label>
                                                   <input
                                                        name="xFrameOptions"
                                                        type="text"
                                                         className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                         onChange={(event) => handleInputChange(event, 'xFrameOptions')}
                                                          value={inputValues.xFrameOptions}
                                                          readOnly={!isCheckboxEnabled}
                                                        />
                                                </div>
                                                  
                                                <div className="col-sm-6">
                                                   <label className="font-w60011">
                                                     X-XSS-Protection
                                                     </label>
                                                  <input
                                                      name="xXSSProtection"
                                                      type="text"
                                                       className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                       onChange={(event) => handleInputChange(event, 'xXSSProtection')}
                                                       value={inputValues.xXSSProtection}
                                                      readOnly={!isCheckboxEnabled}
                                                    />
                                                  </div>

                                                <div className="col-sm-6">
                                                     <label className="font-w60011">
                                                     Content-Security-Policy
                                                     </label>
                                                    <input
                                                      name="contentSecurityPolicy"
                                                      type="text"
                                                      className={`custom-textfield ${isCheckboxEnabled ? 'enabled' : 'disabled'}`}
                                                      onChange={(event) => handleInputChange(event, 'contentSecurityPolicy')}
                                                      value={inputValues.contentSecurityPolicy}
                                                      readOnly={!isCheckboxEnabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                     )} 
                               </div>

                      <div className="col-sm-7 mt-4 ">
                        <button type="submit" className="next btn btn-edit">
                          Save
                        </button>
                        <ToastContainer autoClose={2000} transition={Flip} />
                        <div>
                            <div   style={{marginLeft:"105px",marginTop:"-80px"}} className="Showadvanced" onClick={handleClick}>
                              {showAdvanced ? <u>Hide Advanced Configuration</u> : <u>Show Advanced Configuration</u>}
                             </div>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <Modal className="fade" show={virtualServerModal}>
            <VirtualServer
              virtualServer={defaultVirtualServer}
              close={closeVirtualServerModal}
              save={saveVirtualServer}
            />
          </Modal>

          <Modal className="fade" show={backendServerModal}>
            <BackendServer
              backendServer={defaultBackendServer}
              close={closeBackendServerModal}
              save={saveBackendServer}
            />
          </Modal>

          <Modal className="fade" show={resourceModal.open}>
            <Resource
              resource={defaultResource}
              close={closeResourceModal}
              save={saveResource}
            />
          </Modal>

          <Modal className="fade" show={headerMappingModal}>
            <HeaderMapping
              headerMapping={defaultHeaderMapping}
              close={closeHeaderMappingModal}
              save={saveHeaderMapping}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default Application;
