import * as yup from 'yup';

const URL =
/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const EikConfigSchema = yup.object().shape({
  authorization_endpoint: yup.string().matches(URL, "URL is invalid").required("Required"),
  token_endpoint: yup.string().matches(URL, "URL is invalid").required("Required"),
  // introspection_endpoint: yup.string().matches(URL, "URL is invalid").required("Required"),
  end_session_endpoint: yup.string().matches(URL, "URL is invalid").required("Required"),
  jwks_uri: yup.string().matches(URL, "URL is invalid").required("Required"),
  issuer: yup.string().required("Required"),
  userinfo_endpoint: yup.string().matches(URL, "URL is invalid").required("Required"),
  idpClientId: yup.string().required("Required"),
  idpClientSecret: yup.string().required("Required"),
  authnAttribute: yup.string().required("Required"),
  contextPath: yup.string().matches(/^\/.*/, "Context Path must begin with a '/' ").required("Required"),
  ebsLandingPage: yup.string().required("Required"),
  eikRedirectUri: yup.string().required("Required"),
//   trustStore: yup.string().required(),
//   fndUsrCol: yup.string().required(),
  scopes: yup.string().required("Required"),
  eikAgentHostNames: yup.string().required("Required"),
  cookieDomain: yup.string().required("Required"),
  authnType: yup.string().required("Required"),
  jitAccess: yup.boolean().required("Required"),
});

export default EikConfigSchema;