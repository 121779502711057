import * as Yup from "yup";

/* eslint-disable */
const URL =
/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const regex =/^[a-zA-Z0-9][a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+$/;
const ConfigSchema = Yup.object().shape({
  authorizationUrl: Yup.string()
    .matches(URL, "URL is invalid")
    .required("Required"),
  tokenUrl: Yup.string().matches(URL, "URL is invalid").required("Required"),
  logoutUrl: Yup.string().matches(URL, "URL is invalid").required("Required"),
  jwksUrl: Yup.string().matches(URL, "URL is invalid").required("Required"),
  // userInfoUrl: Yup.string().matches(URL, "URL is invalid").required("Required"),
  issuer: Yup.string().required("Required"),
  audience: Yup.string().required("Required"),
  clientId: Yup.string().required("Required"),
  clientSecret: Yup.string().required("Required"),
  scope: Yup.string().required("Required"),
  cookieDomain: Yup.string().required("Required").matches(regex, "Enter the value in the format: 'example.com'"),
  // sessionDuration: Yup.number().min(1,"Session Duration must be greater than Zero").max(2592000,"SessionDuration must be a number between (1 and 2,592,000)").required("Required"),
  sessionDuration:  Yup.number().min(1,"Session Duration must be a whole number").max(2592000,"Session Duration must be a whole number").required("Required").integer()
  .typeError('Session Duration must be a whole number') 
  .test('is-valid-duration', 'Session Duration must be a whole number', value => /^[0-9]+$/.test(value)),
  
  claims: Yup.array()
    .min(1,("Required"))
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
  syncAgentHostNames: Yup.array()
    .min(1, ("Required"))
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
});

export default ConfigSchema;
