import React,{useEffect, Suspense} from 'react';
import Index from './components/index';
import { withRouter } from 'react-router-dom';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import AppContext from "./context/AppContext";
import agent_template from "./components/Utils/agent_template.json";
import { useAuth, hasAuthParams } from "react-oidc-context";

function App (props) {
	const auth = useAuth();
	
	useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
		return auth.events.addAccessTokenExpiring(() => {
            if (alert("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
                auth.signinSilent();
            }
        })
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect, auth.events, auth.signinSilent]);

    if (auth.activeNavigator) {
        return <div>Redirecting to OIDC Provider for Authentication</div>;
    }
	if (auth.isLoading){
		return <div>Loading Application</div>
	}
    if (!auth.isAuthenticated) {
		auth.signinRedirect();
		return <div>Unable to log in</div>;
    }
	
    return (
        <AppContext.Provider value={{data : agent_template}}>
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
               }
            >
                <Index/>
            </Suspense>
        </AppContext.Provider>
    );
};

export default withRouter((App));