import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Button, Nav, Card, Tab, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApplicationList from "./ApplicationList";
import Ajv from "ajv";
import OpenIdSettings from "./OpenIdSettings";
import agent_template from "../Utils/agent_template.json";
import agent_schema from "../Utils/agent-schema.json";
import noresults from "../../images/no_results.jpg";
import AppContext from "../../context/AppContext";
import Import from "./Import";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const chooseFileTooltip = (props) => (
  <Tooltip id="choosefiletooltip" {...props}>
       Upload the JSON file containing Authenion configuration
  </Tooltip>
);
const Home = () => {
  const inputRef = useRef();
  const value = useContext(AppContext);
  const ajv = new Ajv({ allErrors: true });

  const [data, setData] = useState(agent_template);
  const [openIdSettings, setOpenIdSettings] = useState({
    ...agent_template.tokenProvider,
    ...agent_template.cookieSettings,
  });

  const [ready, setReady] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [schemaErrors, setSchemaErrors] = useState([]);
  
  useEffect(() => {
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);

  }, []);

  const reset = () => {
    setResetModal(false);
    localStorage.setItem("data", JSON.stringify(agent_template));
    setData(agent_template);
    setOpenIdSettings({
      ...agent_template.tokenProvider,
      ...agent_template.cookieSettings,
      ...agent_template.remoteExportSettings,
    });
  };

 const importData = () => {
    inputRef.current.click();
  };

  const openErrorsModal = (errors) => {
    setSchemaErrors(errors);
    setErrorModal(true);
  };

  const closeErrorsModal = () => {
    setSchemaErrors([]);
    setErrorModal(false);
    setImportModal(false);
  };

  const handleSelect = (key) => {
    if(key === 'oid') {
        setOpenIdSettings({ ...data.tokenProvider, ...data.cookieSettings });
        setReady(true);
    }
    else{
        setReady(false);
    }
  }

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      let jsonData = {};
      try {
        jsonData = JSON.parse(e.target.result);
      } catch (e) {
        openErrorsModal([
          {
            message: "The imported file is not a valid json",
          },
        ]);
        return;
      }
      const isDataValid = ajv.validate(agent_schema, jsonData);
      if (!isDataValid) {
        openErrorsModal(ajv.errors);
      } else {
        setOpenIdSettings({
          ...jsonData.tokenProvider,
          ...jsonData.cookieSettings,
          ...jsonData.remoteExportSettings,
         });
         console.log("after setting data");

        localStorage.setItem("data", e.target.result);
        setSchemaErrors([]);
        setData(jsonData);
        setReady(true);
        setImportModal(false);
       }
    };
  };

  return (
    <>
      <Card>
        <Card.Body>
          {/* <!-- Nav tabs --> */}
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="applications" onSelect={handleSelect}>
              <Nav as="ul" className="nav-tabs">
                <Nav.Item as="li" key="applications">
                  <Nav.Link eventKey="applications">
                    <i className={`la la-server mr-2`} />
                    Applications
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item as="li" key="oid">
                  <Nav.Link eventKey="oid">
                    <i className={`la la-tools mr-2`} />
                    Identity Provider Configuration
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <div className="row">
                <div className="col-xl-12">
                  <Tab.Content>
                    <Tab.Pane eventKey="applications">
                      <Card style={{ marginBottom: 0 }}>
                        <Card.Body>
                          <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                            <div className="mb-3 mr-3">
                              <h6 className="fs-16 text-black font-w600 mb-0">
                                {data.applications.length} Total Applications
                              </h6>
                              <span className="fs-14">Based on import</span>
                            </div>

                            <div className="d-flex mb-3">
                              <div className="style-2 default-select mr-3 dropup">
                                <div>
                                  <input
                                    // type="file"
                                    accept=".json"
                                    ref={inputRef}
                                    className="d-none"
                                    onInput={handleChange}
                                    onClick={(e) => {
                                      e.target.value = null;
                                    }}
                                  />
                                  <Button
                                    onClick={() => setImportModal(true)}
                                    className="btn btn-edit text-nowrap"
                                  >
                                    <i
                                      className="fa fa-upload scale5 mr-3"
                                      aria-hidden="true"
                                    />
                                    Import
                                  </Button>
                                </div>
                              </div>

                              <Link
                                to={{
                                  pathname: "/application",
                                  state: { data: data, application: {} },
                                }}
                                className="btn btn-success text-nowrap"
                              >
                                <i
                                  className="fa fa-plus scale5 mr-3"
                                  aria-hidden="true"
                                />
                                New Application
                              </Link>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      {data != null && data.applications.length > 0 ? (
                        <ApplicationList data={data} />
                      ) : (
                        <div id="All" className="tab-pane">
                          <p></p>
                          <Card>
                            <Card.Body>
                              <div className="h-100">
                                <div className="container h-100">
                                  <div className="row justify-content-center h-100 align-items-center">
                                    <div className="col-md-12">
                                      <div className="form-input-content text-center error-page">
                                        <img
                                          className="logo-abbr"
                                          src={noresults}
                                          alt=""
                                        />
                                        <h4>
                                          <i className="la la-server" /> No
                                          Applications found
                                        </h4>
                                        <p>
                                          Use the "Import" button to upload the
                                          JSON (or) use the "New Application"
                                          button to create a new application
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="oid">
                      {ready && (
                        <OpenIdSettings
                          data={data}
                          openIdSettings={openIdSettings}
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>

      <Modal className="fade" show={resetModal}>
        <Modal.Header>
          <Modal.Title>Reset All</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setResetModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <div className="card-body">
          All the imported data will be lost? Do you want to reset?
        </div>
        <Modal.Footer>
          <Button
            className="btn btn-secondary btn-sm light"
            onClick={() => setResetModal(false)}
          >
            Cancel
          </Button>
          <Button onClick={() => reset(false)} className="btn btn-sm">
            Reset
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={importModal}>
        <Modal.Header>
          <Modal.Title>Import Status</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setImportModal(false)}
          >
            <span>&times;</span>
          </Button>
         </Modal.Header>
         <div className="card-body">
          <div>
         <Import/>
         <div style={{color:"black",paddingRight:"345px"}}
         className="fs-16 text-black font-w600 mb-0">
                    Choose File  
                  
                                  <OverlayTrigger
                               placement="right"
                               delay={{ show: 350, hide: 400 }}
                               overlay={chooseFileTooltip}
                               >
                             <svg className="questioncirclechoose" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor"   viewBox="0 0 16 16">
                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                             </svg>
                       </OverlayTrigger>
                       </div>
                  </div>
                  
                  
                            <input
                                    type="file"
                                    accept=".json"
                                    ref={inputRef}
                                    className="d-none"
                                    onInput={handleChange}
                                    onClick={(e) => {
                                      e.target.value = null;
                                    }}
                                  />
                                 
                                <div >
                                  <Button style={{marginTop:"-13px",marginLeft:"7px"}}
                                    onClick={() => importData()}
                                    className="btn btn-edit text-nowrap"
                                  >
                                    <i
                                      className="fa fa-upload scale5 mr-3"
                                      aria-hidden="true"
                                    />
                                    Upload
                                  </Button>
                                  
                                  
                                 </div>
          </div>
      </Modal>


      <Modal className="fade" show={errorModal}>
        <Modal.Header>
          <Modal.Title>Invalid JSON</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => closeErrorsModal()}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <div className="card-body">
          <h5 style={{ color: "red" }}>
            The imported json has the following errors!
          </h5>
          <p></p>
          {schemaErrors.map((error, index) => {
            return (
              <div key={error.instancePath}>
                {error.instancePath} {error.message}
              </div>
            );
          })}
        </div>
        <Modal.Footer>
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => closeErrorsModal()}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
