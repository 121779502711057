import React from "react";
import { Field, ErrorMessage } from "formik";

const TextFieldIDReadOnly = ({ name, label, type }) => (
  <div className="form-group">
    <label className="font-w600">{label}</label>
    <div>
      <Field type={type} name={name} className="form-controlid" readOnly style={{ pointerEvents: "none"}} />
      <ErrorMessage name={name} component="div" className="required" />
    </div>
  </div>
);

export default TextFieldIDReadOnly;
