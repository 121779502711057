import React, { useState, useEffect } from "react";
import {Card, Tab } from "react-bootstrap";
import axios from "axios";
import agent_template from "../Utils/agent_template.json";
import loading from "../../images/loading.gif";
import { useAuth } from "react-oidc-context";
import EIKConfig from "../Utils/EIKConfig_template.json";

var ApplicationSync = (props) => {
  const [status, setStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState(false);
  const urlPath = "/ssolibraryagent/agents/adaptor/update/";

  useEffect(() => {
    setStatus("Not Started");
    setTimeout(() => sync(), 500);
  }, []);

  const sync = () => {
      axios
      .post(
        props.hostName +
        urlPath +
        props.application.id,
        props.data, 
        {
          headers: {
          'Authorization' : `Bearer ${props.token}`
          }
        }
      )
      .then(function (response) {
        setStatus("Success");
      })
      .catch(function (error) {
        setStatusMsg(error.message);
        setStatus("Error");
      });
    
  };

  return (


    <tr role="row" className="odd" key={props.index}>
      <td className="sorting_1">{props.application.virtualServerDefinitionId}</td>
      <td>
        <span className="text-nowrap">{props.application.name}</span>
      </td>
      <td>{props.hostName}</td>
      <td>
        <div className="d-flex align-items-center">
          {status === "Success" && (
            <span className="badge badge-rounded badge-success">Success</span>
          )}

          {status === "Error" && (
            <>
              <span className="badge badge-rounded badge-danger">
                {statusMsg}
              </span>
            </>
          )}

          {status === "Not Started" && (
            <img style={{ height: "30px" }} src={loading} alt="" />
          )}
        </div>
      </td>
    </tr>

  );
};

var HostSync = (props) => {
  const [status, setStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState(false);
  const urlPath = "/ssolibraryagent/agents/ssolibrary/update";

  useEffect(() => {
    setStatus("Not Started");
    setTimeout(() => sync(), 500);
  }, []);

  const sync = () => {
    axios
      .post(props.host + urlPath, props.data, {
        headers: {
        'Authorization' : `Bearer ${props.token}`
        }
        
      })
      .then(function (response) {
        setStatus("Success");
      })
      .catch(function (error) {
        setStatusMsg(error.message);
        setStatus("Error");
      });
    
  };

  return (
    <tr role="row" className="odd" key={props.index}>
      <td>
        <span className="text-nowrap">{props.host}</span>
      </td>
      <td>
    
      </td>
      <td>
        <div className="d-flex align-items-center">
          {status === "Success" && (
            <span className="badge badge-rounded badge-success">Success</span>
          )}
              
          {status === "Error" && (
            <>
              
              <span className="badge badge-rounded badge-danger">
                {" "}
                {statusMsg}{" "}
              </span>
            </>
          )}

          {status === "Not Started" && (
            <img style={{ height: "30px" }} src={loading} alt="" />
          )}
        </div>
      </td>
    </tr>
  );
};

// var EikConfiguratorSync= (props) => {

// const [status, setStatus] = useState(false);
//   const [statusMsg, setStatusMsg] = useState(false);
  
//   const urlPath = "/ssolibraryagent/agents/eiklibrary/eikexport";

//   useEffect(() => {
//     setStatus("Not Started");
//     setTimeout(() => sync(), 500);
//   }, []);

//   const sync = () => {
//       axios
//       .post(props.host + urlPath, props.data, 
//         {
//           headers: {
//           'Authorization' : `Bearer ${props.token}`
//           }
//         }
//       )
//       .then(function (response) {
//         setStatus("Success");
//       })
//       .catch(function (error) {
//         setStatusMsg(error.message);
//         setStatus("Error");
//       });
    
//   };

//   return (
//     <tr role="row" className="odd" key={props.index}>
//       <td>
//         <span className="text-nowrap">{props.host}</span>
//       </td>
//       <td>
//         <div className="d-flex align-items-center">
//           {status === "Success" && (
//             <span className="badge badge-rounded badge-success">Success</span>
//           )}
          
//             {status === "Error" && (
//             <>
              
//               <span className="badge badge-rounded badge-danger">
//                 {" "}
//                 {statusMsg}{" "}
//               </span>
//             </>
//           )}

//           {status === "Not Started" && (
//             <img style={{ height: "30px" }} src={loading} alt="" />
//           )}
//         </div>
//       </td>
//     </tr>
//   );
// };

const SyncAgents = (props) => {
  const [data, setData] = useState(agent_template);
  const [formvalues,setformvalues] =useState(EIKConfig);
  const [hostsCount, setHostsCount] = useState(0);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [eikConfiguratorCount, setEikConfiguratorCount] = useState(0);
  const [accessToken, setAccessToken] = useState(0);
  const auth = useAuth();
  useEffect(() => {
	let accessToken = auth.user?.access_token;
    setAccessToken(accessToken);
    
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);

    // let formvalues =
    //   localStorage.getItem("formvalues") != null
    //     ? JSON.parse(localStorage.getItem("formvalues"))
    //     : EIKConfig;
    // setformvalues(formvalues);
    
    // let eikConfiguratorCount =
    // formvalues &&
    // formvalues.eikAgentHostNames &&
    // formvalues.eikAgentHostNames.length > 0
    //   ? formvalues.eikAgentHostNames.length
    //   : 0;
    //   setEikConfiguratorCount(eikConfiguratorCount);
      

     let hostsCount =
      data &&
      data.tokenProvider.syncAgentHostNames &&
      data.tokenProvider.syncAgentHostNames.length > 0
        ? data.tokenProvider.syncAgentHostNames.length
        : 0;
    setHostsCount(hostsCount);

    let applicationsCount =
      data && data.applications[0] && data.applications[0].id !== ""
        ? data.applications.length
        : 0;
    setApplicationsCount(applicationsCount);
  }, []);

  return (
    <>
      {/* <!-- Nav tabs --> */}
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="applications">
          <Card style={{ marginBottom: 0 }}>
            <Card.Body style={{ paddingBottom: 0 }}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                <div className="mb-3 mr-3">
                  <h6 className="fs-16 text-black font-w600 mb-0">
                    {hostsCount} Total Instances
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>

          {hostsCount > 0 && (
            <div id="All" className="tab-pane">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display dataTablesCard dataTable no-footer"
                    role="grid"
                    aria-describedby="example2_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: 70 }}
                        >
                          Authenion Instances
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label=": activate to sort column ascending"
                          style={{ width: "46px" }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.tokenProvider.syncAgentHostNames.map(
                        (hostName, index) => {
                          return (
                            <HostSync
                              data={data}
                              host={hostName}
                              index={index}
                              token={accessToken} 
                              key="hostSync"
                            />
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
{/* 
         <Card style={{ marginBottom: 0 }}>
            <Card.Body style={{ paddingBottom: 0 }}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                <div className="mb-3 mr-3">
                  <h6 className="fs-16 text-black font-w600 mb-0">
                     EIK Instances
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>

          {eikConfiguratorCount > 0 && (
            <div id="All" className="tab-pane">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display dataTablesCard dataTable no-footer"
                    role="grid"
                    aria-describedby="example2_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc"
                          tabIndex={0}
                          rowSpan={1}
                          colSpan={1}
                          style={{ width: 70 }}
                        >
                          Authenion Instances
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label=": activate to sort column ascending"
                          style={{ width: "46px" }}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {[formvalues.eikAgentHostNames].map((eikAgentHostNames, index) => (
                        <EikConfiguratorSync
                                      data={formvalues}
                                      host={eikAgentHostNames}
                                      index={index}
                                      token={accessToken} 
                                      key="EikConfiguratorSync"
                                      />
                               ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )} */}


          <Card style={{ marginBottom: 0 }}>
            <Card.Body style={{ paddingBottom: 0 }}>
              <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                <div className="mb-3 mr-3">
                  <h6 className="fs-16 text-black font-w600 mb-0">
                    {applicationsCount} Total Applications
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div className="row">
            <div className="col-xl-12">
              {applicationsCount > 0 && (
                <div id="All" className="tab-pane">
                  <div className="table-responsive">
                    <div
                      id="applications_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="applications"
                        className="table card-table display dataTablesCard dataTable no-footer"
                        role="grid"
                        aria-describedby="applications_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_asc"
                              tabIndex={0}
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 70,fontSize:14 }}
                            >
                              VirtualServer ID
                            </th>
                            <th
                              className="sorting_asc"
                              tabIndex={0}
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 70,fontSize:14 }}
                            >
                              Application Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex={0}
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 71,fontSize:14 }}
                            >
                              Authenion Instance
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="applications"
                              rowSpan="1"
                              colSpan="1"
                              aria-label=": activate to sort column ascending"
                              style={{ width: "46px",fontSize:15 }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.applications.map((application, index) => {

                            return(
                            <>
                                {application.syncAgentHostNames.map(
                                  (hostName, index) => {
                                    return (
                                      <ApplicationSync
                                         data={data}
                                         hostName={hostName}
                                         application={application}
                                         index={index}
                                         token={accessToken}
                                         key="ApplicationSync"
                                      />
                                    );
                                  }
                                )}
                             </>

                            )

                          })}
                        </tbody>
                        
                      </table>
                    </div>
                  </div>
                </div>
              )}
              
            </div>
            
          </div>
        </Tab.Container>
      </div>
    </>
  );
};
export default SyncAgents;

