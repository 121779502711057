import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from 'react-router-dom';
import  ThemeContext  from "./context/ThemeContext";
import oidcConfig from "./config.json"

function ConfigLoader({ children }) {
  const [config, setConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch("/config.json")
      .then(response => response.json())
      .then(data => {
        setConfig(data);
        setIsLoading(false);
      })
      .catch(error => {
		console.error(error);
        console.warn("Using config from file instead of fetch. ");
		setConfig(oidcConfig);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
	return null;
  }

  return (
    <BrowserRouter>
      <AuthProvider {...config}>
        <ThemeContext>
          <App />
        </ThemeContext>
      </AuthProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<ConfigLoader />, document.getElementById("root"));