import React, { useState, useEffect } from "react";
import { Card, Tab, Modal } from "react-bootstrap";
import agent_template from "../Utils/agent_template.json";
import noresults from "../../images/no_results.jpg";
import { Link } from "react-router-dom";
import VirtualServer from "../Application/VirtualServer";
import { v4 as uuidv4 } from 'uuid';

const VirtualServerList = (props) => {
  const [data, setData] = useState(agent_template);
  const [virtualServerModal, setVirtualServerModal] = useState(false);
  const [virtualServer, setVirtualServer] = useState(false);

  useEffect(() => {
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);
  }, []);

  let deleteVirtualServer = (id) => {
    let virtualServers = data.virtualServers.filter(function (obj) {
      return obj.id !== id;
    });
    data.virtualServers = virtualServers;

    data.applications.map((application) => {
      if (application.virtualServerDefinitionId === id) {
        application.virtualServerDefinitionId = "";
      }
    });
    localStorage.setItem("data", JSON.stringify(data));
  };

  let openVirtualServerModal = (virtualServer) => {
    setVirtualServer(virtualServer);
    setVirtualServerModal(true);
  };

  let closeVirtualServerModal = () => {
    //setVirtualServer({});
    setVirtualServerModal(false);
  };

  const defaultVirtualServer = {
    id: uuidv4(),
    name: "",
    host: "",
    port: "",
    useSSL: false,
  };

  const saveVirtualServer = (values) => {
    const intPort = parseInt(values.port, 10);
    values.port = intPort;

    let virtualServers = data.virtualServers;

    const existingvirtualServer = virtualServers.find(
      (virtualServer) =>
      virtualServer.name === values.name && virtualServer.id !== values.id
    );
  
    if (existingvirtualServer) {
      alert("Error: virtualServer name already exists.");
      return;
    }
   let objIndex = virtualServers.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );

    if (objIndex >= 0) {
      virtualServers[objIndex] = values;
    } else {
      virtualServers.push(values);
    }
    setVirtualServerModal(false);
    localStorage.setItem("data", JSON.stringify(data));
  };

  let size =
    data === null ||
    data.virtualServers.length === 0 ||
    data.virtualServers[0].id === ""
      ? 0
      : data.virtualServers.length;
  return (
    <>
      <Card>
        <Card.Body>
          {/* <!-- Nav tabs --> */}
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="applications">
              <Card style={{ marginBottom: 0 }}>
                <Card.Body>
                  <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                    <div className="mb-3 mr-3">
                      <h6 className="fs-16 text-black font-w600 mb-0">
                        {size} Total Virtual Servers
                      </h6>
                      <span className="fs-14">Based on import</span>
                    </div>

                    <div className="d-flex mb-3">
                      <Link
                        to="#"
                        className="btn btn-success text-nowrap"
                        onClick={() =>
                          openVirtualServerModal(defaultVirtualServer)
                        }
                      >
                        <i
                          className="fa fa-plus scale5 mr-3"
                          aria-hidden="true"
                        />
                        New Virtual Server
                      </Link>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <div className="row">
                <div className="col-xl-12">
                  <Tab.Content>
                    <Tab.Pane eventKey="applications">
                      {size === 0 ? (
                        <div id="All" className="tab-pane">
                          <p></p>
                          <Card>
                            <Card.Body>
                              <div className="h-100">
                                <div className="container h-100">
                                  <div className="row justify-content-center h-100 align-items-center">
                                    <div className="col-md-12">
                                      <div className="form-input-content text-center error-page">
                                        <img
                                          className="logo-abbr"
                                          src={noresults}
                                          alt=""
                                        />
                                        <h4>
                                          <i className="la la-server" /> No
                                          Virtual Servers found
                                        </h4>
                                        <p>
                                          Use the "New Virtual Server" button to
                                          create a new virtual server
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      ) : (
                        <div id="All" className="tab-pane">
                          <div className="table-responsive">
                            <div
                              id="example2_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example2"
                                className="table card-table display dataTablesCard dataTable no-footer"
                                role="grid"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr role="row">
                                    {/* <th
                                      className="sorting_asc"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 70 }}
                                    >
                                      ID
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 71 }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 126 }}
                                    >
                                      Host
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 66 }}
                                    >
                                      Port
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 66 }}
                                    >
                                      Use SSL
                                    </th>
                                    
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="example2"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label=": activate to sort column ascending"
                                      style={{ width: "46px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.virtualServers.map(
                                    (virtualServer, index) => {
                                      return (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={index}
                                        >
                                          {/* <td className="sorting_1">
                                           {virtualServer.id}
                                          </td> */}
                                          <td>
                                            <span className="text-nowrap">
                                              {virtualServer.name}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              {virtualServer.host}
                                            </span>
                                          </td>
                                          <td>{virtualServer.port}</td>
                                          <td>
                                            {virtualServer.useSSL
                                              ? "True"
                                              : "False"}
                                          </td>
                                          
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <Link
                                                to="#"
                                                className="mr-4"
                                                onClick={() =>
                                                  openVirtualServerModal(
                                                    virtualServer
                                                  )
                                                }
                                              >
                                                <i className="las la-pencil-alt scale-2"></i>
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  deleteVirtualServer(
                                                    virtualServer.id
                                                  )
                                                }
                                              >
                                                <i className="las la-trash-alt scale-2 text-danger"></i>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>

      <Modal className="fade" show={virtualServerModal}>
        <VirtualServer
          virtualServer={virtualServer}
          save={saveVirtualServer}
          close={closeVirtualServerModal}
        />
      </Modal>
    </>
  );
};

export default VirtualServerList;
