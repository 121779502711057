import React, { useState, useEffect } from "react";
import { Card, Tab, Modal } from "react-bootstrap";
import agent_template from "../Utils/agent_template.json";
import noresults from "../../images/no_results.jpg";
import { Link } from "react-router-dom";
import Resource from "../Application/Resource";
import { v4 as uuidv4 } from 'uuid';
var ResourceItem = (props) => {
  return (
    <ul>
      {props.resources.map((resource) => {
        return <li key={resource}>{resource}</li>;
      })}
    </ul>
  );
};

const ResourceList = (props) => {
  const [data, setData] = useState(agent_template);
  const [resourceModal, setResourceModal] = useState(false);
  const [resource, setResource] = useState(false);

  useEffect(() => {
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);
  }, []);

  let deleteResource = (id) => {
    let resources = data.resources.filter(function (obj) {
      return obj.id !== id;
    });
    data.resources = resources;

    data.applications.map((application) => {
      let protectedResources = application.protectedResources.filter(function (
        res
      ) {
        return res !== id;
      });
      application.protectedResources = protectedResources;
      if(application.excludedResources != undefined){
      let excludedResources = application.excludedResources.filter(function (
        res
      ) {
        return res !== id;
      });
      application.excludedResources = excludedResources;
    }
    });

    localStorage.setItem("data", JSON.stringify(data));
  };

  let size =
    data == null || data.resources.length === 0 || data.resources[0].id === ""
      ? 0
      : data.resources.length;
  let openResourceModal = (resource) => {
    setResource(resource);
    setResourceModal(true);
  };

  let closeResourceModal = () => {
   
    setResourceModal(false);
  };

  const defaultResource = {
    id: uuidv4(),
    name: "",
    resourcesUri: [],
  };

  const saveResource = (values) => {
    let resources = data.resources;
    const existingResource = resources.find(
      (resource) =>
        resource.name === values.name && resource.id !== values.id
    );
  
    if (existingResource) {
      alert("Error: Resource name already exists.");
      return;
    }

    let objIndex = resources.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );

    let resourcesUri = values.resourcesUri.map((obj) => obj.value);
    values.resourcesUri = resourcesUri;

    if (objIndex >= 0) {
      resources[objIndex] = values;
    } else {
      resources.push(values);
    }

    setResourceModal(false);
    localStorage.setItem("data", JSON.stringify(data));
  };

  return (
    <>
      <Card>
        <Card.Body>
          {/* <!-- Nav tabs --> */}
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="applications">
              <Card style={{ marginBottom: 0 }}>
                <Card.Body>
                  <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                    <div className="mb-3 mr-3">
                      <h6 className="fs-16 text-black font-w600 mb-0">
                        {size} Total Resources
                      </h6>
                      <span className="fs-14">Based on import</span>
                    </div>

                    <div className="d-flex mb-3">
                      <Link
                        to="#"
                        className="btn btn-success text-nowrap"
                        onClick={() => openResourceModal(defaultResource)}
                      >
                        <i
                          className="fa fa-plus scale5 mr-3"
                          aria-hidden="true"
                        />
                        New Resource
                      </Link>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <div className="row">
                <div className="col-xl-12">
                  <Tab.Content>
                    <Tab.Pane eventKey="applications">
                      {size === 0 ? (
                        <div id="All" className="tab-pane">
                          <p></p>
                          <Card>
                            <Card.Body>
                              <div className="h-100">
                                <div className="container h-100">
                                  <div className="row justify-content-center h-100 align-items-center">
                                    <div className="col-md-12">
                                      <div className="form-input-content text-center error-page">
                                        <img
                                          className="logo-abbr"
                                          src={noresults}
                                          alt=""
                                        />
                                        <h4>
                                          <i className="la la-server" /> No
                                          Resources found
                                        </h4>
                                        <p>
                                          Use the "New Resource" button to
                                          create a new resource
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      ) : (
                        <div id="All" className="tab-pane">
                          <div className="table-responsive">
                            <div
                              id="example2_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example2"
                                className="table card-table display dataTablesCard dataTable no-footer"
                                role="grid"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr role="row">
                                    {/* <th
                                      className="sorting_asc"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 70 }}
                                    >
                                      ID
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 71 }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 126 }}
                                    >
                                      Resources Path
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="example2"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label=": activate to sort column ascending"
                                      style={{ width: "46px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.resources.map((resource, index) => {
                                    return (
                                      <tr
                                        role="row"
                                        className="odd"
                                        key={index}
                                      >
                                        {/* <td className="sorting_1">
                                          {resource.id}
                                        </td> */}
                                        <td>
                                          <Link
                                            className="has-arrow ai-icon"
                                            to={{
                                              pathname: "/application",
                                              state: {
                                                data: props.data,
                                                resource: resource,
                                              },
                                            }}
                                          >
                                            {resource.name}
                                          </Link>
                                        </td>
                                        <td>
                                          {resource.resourcesUri.length > 0 && (
                                            <ResourceItem
                                              resources={resource.resourcesUri}
                                            />
                                          )}
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <Link
                                              to="#"
                                              className="mr-4"
                                              onClick={() =>
                                                openResourceModal(resource)
                                              }
                                            >
                                              <i className="las la-pencil-alt scale-2"></i>
                                            </Link>
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                deleteResource(resource.id)
                                              }
                                            >
                                              <i className="las la-trash-alt scale-2 text-danger"></i>
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>

      <Modal className="fade" show={resourceModal}>
        <Resource
          resource={resource}
          close={closeResourceModal}
          save={saveResource}
        />
      </Modal>
    </>
  );
};

export default ResourceList;