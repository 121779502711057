import React, {useState} from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import TextField from "../Utils/TextField";
import HeaderMappingSchema from "../Validation/HeaderMappingSchema";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TextFieldIDReadOnly from "../Utils/TextFieldIDReadOnly";

const HeaderMapping = (props) => {
  const { close, save, headerMapping } = props;
  const [oidcClaims] = useState( () => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (data) {
      return data.tokenProvider.claims || [];
    }
    else{
      return [];
    }
  });

  const idHmTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
       Enter a unique ID for the Header Mapping
    </Tooltip>
    );

    const nameHmTooltip = (props) => (
      <Tooltip  id="button-tooltip"  {...props}  >
         Enter a name for the Header Mapping
      </Tooltip>
      );

      const appHmTooltip = (props) => (
        <Tooltip  id="button-tooltip"  {...props}  >
           Enter the name of the HTTP Header, the target application requires
        </Tooltip>
        );

        const oidcHmTooltip = (props) => (
          <Tooltip  id="button-tooltip"  {...props}  >
             Select the attribute from OIDC claims to map with the HTTP Header
          </Tooltip>
          );


  return (
    <Formik
      initialValues={headerMapping}
      enableReinitialize
      validationSchema={HeaderMappingSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        save(values);
      }}
    >
      {({ values }) => (
        <>
          <Modal.Header>
            <Modal.Title>Header Mapping</Modal.Title>
            <Button variant="" className="close" onClick={() => close()}>
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Form>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                    {/* <OverlayTrigger
                           placement="bottom-end"
                           delay={{ show: 250, hide: 400 }}
                           overlay={idHmTooltip}
                          >  */}
                  <div className="col-sm-5">
                  <TextFieldIDReadOnly name="id" type="text" label="ID" />
                  </div>
                  {/* </OverlayTrigger> */}

                  <OverlayTrigger
                                  placement="bottom-end"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={nameHmTooltip}
                                   >            
                  <div className="col-sm-5">
                    <TextField name="name" type="text" label="Name" />
                  </div>
                  </OverlayTrigger>

                  <div className="col-sm-5">
                    <label  className="font-w600">App Header</label>
                    <OverlayTrigger
                                             placement="left"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={appHmTooltip}
                                              > 
                                              <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                          </svg>
                                               </OverlayTrigger>
                  </div>
                  <div className="col-sm-5">
                    <label className="font-w600">OIDC Claim</label>
                    <OverlayTrigger
                                             placement="right"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={oidcHmTooltip}
                                              > 
                             <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                          </svg>
                 </OverlayTrigger>
                  </div>
                  <div className="col-md-12">

                  <FieldArray name="claimMappings">
                              {({ insert, remove, push }) => (
                                <div>
                                  {values.claimMappings.length > 0 &&
                                    values.claimMappings.map((claimMapping, index) => (
                                      <div className="row" key={index}>
                                         
                                        <div className="col-sm-5">
                                          <Field className="form-control"
                                            name={`claimMappings.${index}.headerName`}
                                            type="text"
                                          />
                                          <ErrorMessage
                                                name={`claimMappings.${index}.headerName`}
                                             component="div"
                                             className="required"
                                          />
                                        </div>
                                       

                                      
                                        <div className="col-sm-5">
                                          <Field component="select" className="form-control"
                                            name={`claimMappings.${index}.headerValue`}>
                                              <option key="default" value=""> Select claim </option>
                                              {oidcClaims.map((value) => (<option key={value} value={value}> {value} </option>)) }
                                          </Field>
                                          <ErrorMessage
                                                name={`claimMappings.${index}.headerValue`}
                                             component="div"
                                             className="required"
                                          />
                                        </div>
                                      
                                        <div className="col-sm-2" style={{padding:"0px",marginTop:"4px",marginBottom:"21px"}}>
                                            <Button style={{padding: "0.438rem 0.7rem"}}
                                              className="btn btn-xs btn-danger"
                                             onClick={() => remove(index)}>
                                              <i className="las la-trash-alt scale-2"></i>
                                            </Button>
                                        </div>
                                      </div>
                                    ))}
                                  <Button
                                    type="button"
                                     className="btn btn-xs"
                                    onClick={() => push({ headerName: '', headerValue: '' })}
                                  >
                                    + Add Header
                                  </Button>
                                </div>
                              )}
                            </FieldArray>
                  </div>
                </div>
              </div>
            </div>

            <Modal.Footer>
              <Button
                onClick={() => close()}
                className="btn btn-secondary btn-sm light"
              >
                Cancel
              </Button>
              <Button type="submit" className="btn btn-sm">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default HeaderMapping;
