import React from "react";
import { Button, Modal } from "react-bootstrap";

import { Formik, Form, Field as FormikField } from "formik";
import TextField from "../Utils/TextField";
import VirtualServerSchema from "../Validation/VirtualServerSchema";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TextFieldIDReadOnly from "../Utils/TextFieldIDReadOnly";

const VirtualServer = (props) => {
  const { close, save, virtualServer } = props;

  const idVsTooltip = (props) => (
    <Tooltip  id="button-tooltip"  {...props}  >
       Enter a unique ID for Virtual Server
    </Tooltip>
    );

    const nameVsTooltip = (props) => (
      <Tooltip  id="button-tooltip"  {...props}  >
         Enter a name for the Virtual Server
      </Tooltip>
      );

      const hostVsTooltip = (props) => (
        <Tooltip  id="button-tooltip"  {...props}  >
           Enter the Hostname of the Virtual Server
        </Tooltip>
        );

        const portVsTooltip = (props) => (
          <Tooltip  id="button-tooltip"  {...props}  >
             Enter the port number for the Virtual Server
          </Tooltip>
          );

          const sslVsTooltip = (props) => (
            <Tooltip  id="checkbox-tooltip" className="sslVsTooltip" {...props}  >
               Does the Virtual Server requires HTTPS connections?
            </Tooltip>
            );

  return (
    <Formik
      initialValues={virtualServer}
      enableReinitialize
      validationSchema={VirtualServerSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        save(values);
      }}
    >
      {({ isSubmitting }) => (
        <>
          <Modal.Header>
            <Modal.Title>Virtual Server</Modal.Title>
            <Button variant="" className="close" onClick={() => close()}>
              <span>&times;</span>
            </Button>
          </Modal.Header>

          <Form>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                {/* <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={idVsTooltip}
                               >  */}
                  <div className="col-sm-6">
                  <TextFieldIDReadOnly name="id" type="text" label="ID" />
                  </div>
                  {/* </OverlayTrigger> */}

                  <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={nameVsTooltip}
                               >
                  <div className="col-sm-6">
                    <TextField name="name" type="text" label="Name" />
                  </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={hostVsTooltip}
                               >
                  <div className="col-sm-6">
                    <TextField name="host" type="text" label="Host" />
                  </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                               placement="bottom-end"
                               delay={{ show: 250, hide: 400 }}
                               overlay={portVsTooltip}
                               >
                  <div className="col-sm-6">
                    <TextField name="port" type="number" label="Port" />
                  </div>
                  </OverlayTrigger>


                  <OverlayTrigger
                               placement="bottom-start"
                               delay={{ show: 250, hide: 400 }}
                               overlay={sslVsTooltip}
                               >
                  <div className="col-sm-6">
                    <label className="font-w600">Use SSL</label>
                    <div>
                      <FormikField
                        className="custom-checkbox mb-3 checkbox-warning check-xl"
                        type="checkbox"
                        name="useSSL"
                      />
                    </div>
                  </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <Modal.Footer>
              <Button
                onClick={() => close()}
                className="btn btn-secondary btn-sm light"
              >
                Cancel
              </Button>
              <Button type="submit" className="btn btn-sm">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default VirtualServer;
