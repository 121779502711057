import * as Yup from "yup";

const HeaderMappingSchema = Yup.object().shape({
  id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  claimMappings: Yup.array().min(1).of(
    Yup.object().shape({
      headerName: Yup.string().required("Required"),
      headerValue: Yup.string().required("Required")
      })
    )
  
});



export default HeaderMappingSchema;
