import React, { useState, useEffect } from "react";
import { Card, Tab, Modal } from "react-bootstrap";
import agent_template from "../Utils/agent_template.json";
import noresults from "../../images/no_results.jpg";
import { Link } from "react-router-dom";
import BackendServer from "../Application/BackendServer";
import { v4 as uuidv4 } from 'uuid';
var Resource = (props) => {
  return (
    <ul>
      {props.resources.map((resource) => {
        return <li key={resource}>{resource}</li>;
      })}
    </ul>
  );
};

const BackendServerList = (props) => {
  const [data, setData] = useState(agent_template);
  const [backendServerModal, setBackendServerModal] = useState(false);
  const [backendServer, setBackendServer] = useState(false);

  let deleteBackendServer = (id) => {
    let backendServers = data.backendServers.filter(function (obj) {
      return obj.id !== id;
    });
    data.backendServers = backendServers;
    //delete application
    data.applications.map((application) => {
      if (application.backendServerDefinitionId === id) {
        application.backendServerDefinitionId = "";
      }
    });
    localStorage.setItem("data", JSON.stringify(data));
  };

  useEffect(() => {
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
    setData(data);
  }, []);

  let size =
    data == null ||
    data.backendServers.length === 0 ||
    data.backendServers[0].id === ""
      ? 0
      : data.backendServers.length;

  let openBackendServerModal = (backendServer) => {
    setBackendServer(backendServer);
    setBackendServerModal(true);
  };

  let closeVirtualServerModal = () => {
    
    setBackendServerModal(false);
  };

  const defaultBackendServer = {
    id: uuidv4(),
    name: "",
    urls: [],
    useSSL: false,
    enableSessionPersistence: false,
  };

  const saveBackendServer = (values) => {
    
    let backendServers = data.backendServers;
    const existingBackendServer = backendServers.find(
      (backendServer) =>
      backendServer.name === values.name && backendServer.id !== values.id
    );
  
    if (existingBackendServer) {
      alert("Error: BackendServer name already exists.");
      return;
    }

    let objIndex = backendServers.findIndex(
      (obj) => obj.id === values.id || obj.id === ""
    );

    let urls = values.urls.map((obj) => obj.value);
    values.urls = urls;

    if (objIndex >= 0) {
      backendServers[objIndex] = values;
    } else {
      backendServers.push(values);
    }
    setBackendServerModal(false);
    localStorage.setItem("data", JSON.stringify(data));
  };

  return (
    <>
      <Card>
        <Card.Body>
          {/* <!-- Nav tabs --> */}
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey="applications">
              <Card style={{ marginBottom: 0 }}>
                <Card.Body>
                  <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between ">
                    <div className="mb-3 mr-3">
                      <h6 className="fs-16 text-black font-w600 mb-0">
                        {size} Total Application Hosts
                      </h6>
                      <span className="fs-14">Based on import</span>
                    </div>

                    <div className="d-flex mb-3">
                      <Link
                        to="#"
                        className="btn btn-success text-nowrap"
                        onClick={() =>
                          openBackendServerModal(defaultBackendServer)
                        }
                      >
                        <i
                          className="fa fa-plus scale5 mr-3"
                          aria-hidden="true"
                        />
                        New Application Host
                      </Link>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <div className="row">
                <div className="col-xl-12">
                  <Tab.Content>
                    <Tab.Pane eventKey="applications">
                      {size === 0 ? (
                        <div id="All" className="tab-pane">
                          <p></p>
                          <Card>
                            <Card.Body>
                              <div className="h-100">
                                <div className="container h-100">
                                  <div className="row justify-content-center h-100 align-items-center">
                                    <div className="col-md-12">
                                      <div className="form-input-content text-center error-page">
                                        <img
                                          className="logo-abbr"
                                          src={noresults}
                                          alt=""
                                        />
                                        <h4>
                                          <i className="la la-server" /> No
                                          Application Hosts found
                                        </h4>
                                        <p>
                                          Use the "New Application Host" button to
                                          create a new application host
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      ) : (
                        <div id="All" className="tab-pane">
                          <div className="table-responsive">
                            <div
                              id="example2_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example2"
                                className="table card-table display dataTablesCard dataTable no-footer"
                                role="grid"
                                aria-describedby="example2_info"
                              >
                                <thead>
                                  <tr role="row">
                                    {/* <th
                                      className="sorting_asc"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 70 }}
                                    >
                                      ID
                                    </th> */}
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 71 }}
                                    >
                                      Name
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 126 }}
                                    >
                                      Hosts
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 80 }}
                                    >
                                      Use SSL
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex={0}
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 80 }}
                                    >
                                      Enable Session Persistence
                                    </th>
                                    <th
                                      className="sorting"
                                      tabIndex="0"
                                      aria-controls="example2"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label=": activate to sort column ascending"
                                      style={{ width: "46px" }}
                                    >
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.backendServers.map(
                                    (backendServer, index) => {
                                      return (
                                        <tr
                                          role="row"
                                          className="odd"
                                          key={index}
                                        >
                                          {/* <td className="sorting_1">
                                            {backendServer.id}
                                          </td> */}
                                          <td>
                                            <span className="text-nowrap">
                                              {backendServer.name}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-nowrap">
                                              {backendServer.urls.length >
                                                0 && (
                                                <Resource
                                                  resources={backendServer.urls}
                                                />
                                              )} 
                                             
                                            </span>
                                          </td>
                                          <td>
                                            {backendServer.useSSL
                                              ? "True"
                                              : "False"}
                                          </td>
                                          <td>
                                            {backendServer.enableSessionPersistence
                                              ? "True"
                                              : "False"}
                                          </td>

                                          <td>
                                            <div className="d-flex align-items-center">
                                              <Link
                                                to="#"
                                                className="mr-4"
                                                onClick={() =>
                                                  openBackendServerModal(
                                                    backendServer
                                                  )
                                                }
                                              >
                                                <i className="las la-pencil-alt scale-2"></i>
                                              </Link>
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  deleteBackendServer(
                                                    backendServer.id
                                                  )
                                                }
                                              >
                                                <i className="las la-trash-alt scale-2 text-danger"></i>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>

      <Modal className="fade" show={backendServerModal}>
        <BackendServer
          backendServer={backendServer}
          close={closeVirtualServerModal}
          save={saveBackendServer}
        />
      </Modal>
    </>
  );
};

export default BackendServerList;