import { Field } from "formik";
import * as Yup from "yup";

const BackendServerSchema = Yup.object().shape({
  id: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  urls: Yup.array()
    .min(1, ("Required"))
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),                   
});

export default BackendServerSchema;