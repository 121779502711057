import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";

/// Layout
import Nav from "./Nav";

/// Dashboard
import Home from "./Dashboard/Home";

import VirtualServerList from "./Dashboard/VirtualServerList";
import BackendServerList from "./Dashboard/BackendServerList";
import HeaderMappingList from "./Dashboard/HeaderMappingList";
import ResourceList from "./Dashboard/ResourceList";
import Application from "./Application/Index";
import EikConfigurator from "./Dashboard/EikConfigurator";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "application", component: Application },
    { url: "virtualservers", component: VirtualServerList },
    { url: "backendservers", component: BackendServerList },
    { url: "headermappings", component: HeaderMappingList },
    { url: "resources", component: ResourceList },
    { url: "eikexport", component: EikConfigurator }
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Markup;