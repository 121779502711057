import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field as FormikField, ErrorMessage } from 'formik';
import TextField from '../Utils/TextField';
import CreatableSelectField from '../Utils/CreatableSelectField';
import BackendServerSchema from "../Validation/BackendServerSchema";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TextFieldIDReadOnly from "../Utils/TextFieldIDReadOnly";
import CreatableSelectScroll from "../Utils/CreatableSelectScroll";

const BackendServer = props => {
  const { close, save, backendServer } = props;

  const idBsTooltip = (props) => (
   <Tooltip  id="button-tooltip"  {...props}  >
      Enter a unique ID for the Application Host
   </Tooltip>
   );

   const nameBsTooltip = (props) => (
      <Tooltip  id="button-tooltip"  {...props}  >
         Enter a name for the Application Host
      </Tooltip>
      );

      const sslBsTooltip = (props) => (
         <Tooltip  id="button-tooltip1"  {...props}  >
            Is the backend Application Host expecting HTTPS connections?
         </Tooltip>
         );

         const enableTooltip = (props) => (
            <Tooltip  id="button-tooltip1"  {...props}  >
               Ensures requests from the same client will be directed to the same server
            </Tooltip>
            );

  const urlBsTooltip = (props) => (
            <Tooltip  id="button-tooltip1"  {...props}  >
               Enter the hostname:port pair for the Application Host
            </Tooltip>
            );
  return (

          <Formik initialValues={backendServer}
                enableReinitialize
                validationSchema={BackendServerSchema}
                onSubmit={(values, { setSubmitting }) => {
                   setSubmitting(false);
                   save(values);
                }}
              >

              {({ isSubmitting }) => (

                            <>
                         <Modal.Header>
                                                   <Modal.Title>Application Host</Modal.Title>
                                                   <Button
                                                      variant=""
                                                      className="close"
                                                      onClick={() => close()}
                                                   >
                                                      <span>&times;</span>
                                                   </Button>
                                                </Modal.Header>
                          <Form>
                           <div className='card-body'>
                           <div className='basic-form'>
                               <div className="row">
                               {/* <OverlayTrigger
                                             placement="bottom-end"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={idBsTooltip}
                                              > */}
                                   <div className="col-sm-6">
                                   <TextFieldIDReadOnly name="id" type="text" label="ID"/>
                                   </div>
                                   {/* </OverlayTrigger> */}

                                   <OverlayTrigger
                                             placement="bottom-end"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={nameBsTooltip}
                                              >
                                  <div className="col-sm-6">
                                     <TextField name="name"  type="text" label="Name"/>
                                   </div>
                                   </OverlayTrigger>

                                  <div className='form-group col-md-12'>
                                        <label className="font-w600">Host</label>
                                         <OverlayTrigger
                                             placement="right"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={urlBsTooltip}
                                              >
                                           <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                          </svg>
                                         </OverlayTrigger>
                                       <FormikField className='form-control dropdown-form-control' component={CreatableSelectScroll} name="urls"
                                       value={backendServer.urls.map(res => ({ value: res, label: res }))}
                                       />
                                        <ErrorMessage name={"urls"} component="div"
                                         className="required"/>
                                    </div>

                                    <div className="col-sm-6">
                                       <label className="font-w600">Use SSL</label>
                                       <OverlayTrigger
                                             placement="bottom-start"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={sslBsTooltip}
                                              >
                                                <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                          </svg>
                                          </OverlayTrigger>
                                       <div>
                                       <FormikField className='custom-checkbox mb-3 checkbox-warning check-xl' type="checkbox" name="useSSL" />
                                       </div>
                                  </div>

                                   <div className="col-sm-6">
                                       <label style={{marginLeft:"-50px"}} className="font-w600">Enable Session Persistence</label>
                                       <OverlayTrigger
                                             placement="bottom-start"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={enableTooltip}
                                              >
                                                <svg id="questioncircle" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                             <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                          </svg>
                                          </OverlayTrigger>
                                       <div>
                                       <FormikField style={{marginLeft:"-47px"}} className='custom-checkbox mb-3 checkbox-warning check-xl' type="checkbox" name="enableSessionPersistence" />
                                       <ErrorMessage name={"enableSessionPersistence"} component="div"
                                         className="required"/>
                                       </div>
                                  </div>
                                  
                                   
                               </div>
                           </div>
                           </div>

                           <Modal.Footer>
                               <Button
                                  onClick={() => close()}
                                 className="btn btn-secondary btn-sm light"
                               >
                                  Cancel
                               </Button>
                               <Button type="submit" className="btn btn-sm">Save</Button>
                           </Modal.Footer>
               </Form>
                   </>
                )}
          </Formik>
  );
};

export default BackendServer;