import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
      <NavHeader />
      <SideBar onClick={() => onClick2()} onClick3={() => onClick3()} />
      <Header toggle={toggle} title={title} />
    </Fragment>
  );
};

export default JobieNav;
