/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import agent_template from "../Utils/agent_template.json";
import AppContext from "../../context/AppContext";
import SyncAgents from "../Dashboard/SyncAgents";
import Status from "../Dashboard/Status";
import { withAuth } from "react-oidc-context";
import EIkconfig from "../Utils/EIKConfig_template.json";
var currentdate = new Date();
var year = currentdate.getFullYear();

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: JSON.stringify(agent_template, null, 2),
      syncModal: false,
      statusModal: false,
    };
  }
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }

  setSyncModal(flag) {
    this.setState({
      syncModal: flag,
    });
  }
  setStatusModal(flag) {
    this.setState({
      statusModal: flag,
    });
  }

  setExportModal(flag) {
      this.setState({
        exportModal: flag,
      });
    }
	
  logoutUser() {
	const auth = this.props.auth;
	auth.stopSilentRenew();
	auth.signoutRedirect({id_token_hint: auth.user?.id_token});
  }

  exportData() {
    let data =
      localStorage.getItem("data") != null
        ? JSON.parse(localStorage.getItem("data"))
        : agent_template;
  
    // let Eikconfigurator =
    //   localStorage.getItem("formvalues") != null
    //     ? JSON.parse(localStorage.getItem("formvalues"))
    //     : EIkconfig;
  
    const dataExport =data;
    // const EikconfigExport =Eikconfigurator;
  
    const jsonData = JSON.stringify(dataExport, null, 2);
    // const EikconfigJsonData = JSON.stringify(EikconfigExport, null, 2);
  
    const dataJsonString = `data:text/json;charset=utf-8,${encodeURIComponent(jsonData)}`;
    // const EikconfigJsonString = `data:text/json;charset=utf-8,${encodeURIComponent(EikconfigJsonData)}`;
  
    const dataLink = document.createElement("a");
    dataLink.href = dataJsonString;
    dataLink.download = "agent-exported.json";
    dataLink.click();
  
    // const EikconfigLink = document.createElement("a");
    // EikconfigLink.href = EikconfigJsonString;
    // EikconfigLink.download = "Eikconfig-exported.json";
    // EikconfigLink.click();
  }
  
  sync() {
    this.setState({
      syncModal: true,
    });
  }
  
  status() {
    this.setState({
      statusModal: true,
    });
  }

  render() {
    /// Path
	let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [];

    return (
      <AppContext.Provider value={{ data: this.state.data }}>
        {/* <div className="deznav" style={{backgroundColor:"#FF0000"}}> */}
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">
            <Button
              as="a"
              className="add-menu-sidebar"
              data-toggle="modal"
              data-target="#addOrderModalside"
              onClick={() => this.sync()}
              style={{ background: "#007bff", borderColor: "#007bff" }}
            >
              <i className="fa fa-refresh scale5 mr-3" aria-hidden="true" />{" "}
              Sync
            </Button>

            <Button
              as="a"
              className="add-menu-sidebar"
              data-toggle="modal"
              data-target="#addOrderModalside"
              onClick={() => this.exportData()}
              style={{ background: "#007bff", borderColor: "#007bff" }}
            >
              <i className="fa fa-download scale5 mr-3" aria-hidden="true" />{" "}
              Export
            </Button>

            <MM className="metismenu" id="menu">
              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" style={{}}   to="/">
                  <i className="flaticon-381-networking"></i>
                  <span className="nav-text">Applications</span>
                </Link>
              </li>

              {/*<li className={`${dashBoard.includes(path) ? "" : ""}`}>
                                      <Link className="has-arrow ai-icon" to="/configuration" >
                                        <i className="flaticon-381-networking"></i>
                                        <span className="nav-text">Configuration</span>
                                    </Link>
                                    </li>
            */}
              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon"  to="/VirtualServers">
                  <i className="flaticon-381-television"></i>
                  <span className="nav-text">Virtual Servers</span>
                </Link>
              </li>

              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" to="/BackendServers">
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text">Application Hosts</span>
                </Link>
              </li>

              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" to="/HeaderMappings">
                  <i className="flaticon-381-layer-1"></i>
                  <span className="nav-text">Header Mappings</span>
                </Link>
              </li>
              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" to="/Resources">
                  <i className="flaticon-381-book"></i>
                  <span className="nav-text">Resources</span>
                </Link>
              </li>
              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" onClick={() => this.status()} to="/">
                  <i className="flaticon-381-notepad"></i>
                  <span className="nav-text">Authenion Status</span>
                </Link>
              </li>
              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" to="/EikExport">
                  <i className="flaticon-381-book"></i>
                  <span className="nav-text">EIK Configurator</span>
                </Link>
              </li>
              <li className={`${dashBoard.includes(path) ? "" : ""}`}>
                <Link className="has-arrow ai-icon" onClick={() => this.logoutUser()} to="/">
                <i className="flaticon-381-lock-2"></i>
                <span className="nav-text">Logout</span>
                </Link>
              </li>
              
            </MM>
            <div className="copyright">
              <p><span className="font-weight-bold">Authenion</span> v 1.0 </p> Copyright © {year} LikeMinds Consulting. All rights reserved
              
            </div>
          </PerfectScrollbar>
        </div>

        <Modal className="fade" show={this.state.syncModal} size="lg">
          <Modal.Header>
            <Modal.Title>Sync Status</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.setSyncModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <div className="card-body">
            <SyncAgents />
          </div>
        </Modal>
        <Modal className="fade" show={this.state.statusModal} size="lg">
          <Modal.Header>
            <Modal.Title> Status</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.setStatusModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <div className="card-body">
            <Status />
          </div>
        </Modal>
      </AppContext.Provider>
    );
  }
}

export default withAuth(SideBar);
