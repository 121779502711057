import * as Yup from "yup";

const ApplicationSchema = Yup.object().shape({
  id: Yup.string().required("Required"),

  name: Yup.string().required("Required"),

  virtualServerDefinitionId: Yup.object().shape({
    label: Yup.string().required("Required"),
  }),

  backendServerDefinitionId: Yup.object().shape({
    label: Yup.string().required("Required"),
  }),

  /* headerMappingId: Yup.object().shape({
    label: Yup.string().required("Required"),
  }), */

  /* excludedResources: Yup.array()
  .min(1,("Required"))
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ), */

  // protectedResources: Yup.array()
  // .min(1,("Required"))
  //   .of(
  //     Yup.object().shape({
  //       label: Yup.string().required(),
  //       value: Yup.string().required(),
  //     })
  //   ),

  syncAgentHostNames: Yup.array()
    .min(1,("Required"))
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      })
    ),
});

export default ApplicationSchema;
