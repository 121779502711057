import React, { useState, useCallback, useEffect } from "react";
import CreatableSelectAuthenionInstance from "react-select/creatable";
import { useField } from "formik";

function CreatableSelectScroll(props) {
  const [, , { setValue, setTouched }] = useField(props.field.name);
  const [values, setValues] = useState();

  useEffect(() => {
    setValue(props.value || []);
    setValues(props.value || []);
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback((inputValue) => {
    if (inputValue == null) {
      inputValue = [];
    }
    setValue(inputValue);
    setValues(inputValue);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CreatableSelectAuthenionInstance
        {...props}
        onChange={handleChange}
        onBlur={setTouched}
        value={values}
        isClearable
        isMulti
        className="form-control1 dropdown-form-control1"
      />
    </>
  );
}

export default CreatableSelectScroll;
